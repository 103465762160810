import React, { useState, useEffect, useRef } from 'react';

import { useAuth } from '../../context/auth';
import Select from 'react-select'

import api from '../../services/api';

import Footer from '../../components/Footer';
import PaymentsPixItem from './PaymentsPixItem'

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  HeaderAction,
  ItemHeader,
  Table, Client, Value, Paid, Action

} from './styles';
import { useCallback } from 'react';
import PaymentsPosItem from './PaymentsPosItem';
import { AiOutlineReload } from 'react-icons/ai';

const PaymentsPix = props => {
  const { restaurantFantasyName, stoneDevice, restaurantHasStone, getPos } = useAuth();
  const [pixes, setPixes] = useState([])
  const [posPayments, setPosPayments] = useState([])

  const footerRef = useRef(null)

  function getPixCount() {
    footerRef.current.getPixesCount()
  }

  const getPaymentsPix = useCallback(async () => {
    const response = await api.get("/waiter/pix")

    setPixes(response.data)
  }, []);

  const getPaymentsPos = useCallback(async () => {
    const response = await api.get(`/waiter/pos/transactions/${stoneDevice.id}`)

    setPosPayments(response.data);
  }, [stoneDevice]);

  useEffect(() => {
    getPaymentsPix();
    if (stoneDevice) {
      getPaymentsPos();
    }
  }, [getPaymentsPix, getPaymentsPos])

  return (
    <Container>
      <Content>
        <HeaderAction>
          <header>
            <h1>{restaurantFantasyName}</h1>
            <div>Pagamentos</div>
          </header>
          {
            restaurantHasStone.toString() === 'true' &&
            <header style={{ marginTop: 5, flexDirection: 'row', float: 'right' }}>
              <div onClick={getPos}>
                <AiOutlineReload size={15} color="#fb4a20" style={{ marginRight: 5 }} />
                {stoneDevice ? stoneDevice.serial_number : 'Sem POS'}
              </div>
            </header>
          }
        </HeaderAction>


        {
          stoneDevice &&
          <>
            <h4 style={{ marginLeft: 10, marginBottom: 10, marginTop: 5 }}>Pagamentos POS</h4>

            <MenuContent style={{ marginBottom: 30 }}>
              <MenuItem>
                <ItemHeader>
                  <Table>Mesa</Table>
                  <Client>Status</Client>
                  <Value>Valor</Value>
                  <Paid>Criado em:</Paid>
                  <Action>Ação</Action>

                </ItemHeader>
                {posPayments && posPayments.length > 0 && (
                  <PaymentsPosItem transaction={posPayments[0]} getPaymentsPos={getPaymentsPos} isFirst={true} />
                )}
                {posPayments && posPayments.length > 0 && posPayments.filter(pay => pay.id !== posPayments[0]?.id).map(pix => (
                  <PaymentsPosItem transaction={pix} getPaymentsPos={getPaymentsPos} />
                ))}
              </MenuItem>
            </MenuContent>
          </>
        }

        <h4 style={{ marginLeft: 10, marginBottom: 10, marginTop: 38 }}>Pagamentos Pix</h4>

        <MenuContent>
          <MenuItem>
            <ItemHeader>
              <Table>Mesa</Table>
              <Client>Cliente</Client>
              <Value>Valor</Value>
              <Paid>Pago em:</Paid>
              <Action>Checar</Action>

            </ItemHeader>
            {pixes && pixes.length > 0 && pixes.filter(item => item.checked === false).map(pix => (
              <PaymentsPixItem pix={pix} pixes={pixes} setPixes={setPixes} getPixCount={getPixCount} />
            ))}
          </MenuItem>
        </MenuContent>

        <Footer ref={footerRef} />
      </Content>
    </Container>
  );
};

export default PaymentsPix
