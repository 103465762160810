import styled, { css } from 'styled-components';

export const Container = styled.nav`
  margin-top: 10px;
  height: 110px;
  display: inline-flex;
  width: 100%;
  max-width: 414px;
  padding-left: 8px;
  .scroll-container {
    display: flex;
    align-content: center;
    padding-bottom: 15px;
  }


`;

export const CategoryThumbnailLink = styled.a`

  width: 64px;
  height: 65px;
  background: ${props => props.alan === 'selected' ? '#ff3c3a' : '#FFF'};
  position: relative;
  border-radius: 100%;
  box-shadow: 2px 2px 3px #33333320;
color: ${props => props.alan === 'selected' ? '#fff' : '#ff3c3a'};
font-size: 22px;
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
align-items: center;
  font-weight: bold;


  &:after {
    content: '';
    position: absolute;
    border-radius: 100%;
    top: -3px;
    left: -3px;
    bottom: -3px;
    right: -3px;
    border: 1px #ff3c3a solid;

    ${props => props.buttonAdd && css`
        border: 1px #3BD2C1 solid;
    `}

  }


`;

export const CategoryList = styled.li`
  width: 80px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  list-style: none;



  padding-top: 5px;

  p {
    font-size: 12px;
    max-width: 73px;
    margin-top: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

  }


`;
