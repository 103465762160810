import React from 'react';


// import { Modal, ModalFooter } from 'reactstrap';
import {

  FiX,
} from 'react-icons/fi';


import ScrollToTop from '../../../components/ScrollToTop';
import {
  Container,
  Content,
  ImageProduct,
  DescriptionProduct,
  Footer,
  CloseButton,
  ProductName,
} from './styles';


const ProductDetails = ({ product, closeProductModal }) => {


  return (
    <Container>
      <ScrollToTop />
      <Content>


        {product.image && (
          <ImageProduct>
            <img src={product.image.url_thumb || product.image.url} alt={product.description} />
          </ImageProduct>
        )}

        <DescriptionProduct>
          {!product.image ? (
            <ProductName>{product.name}</ProductName>
          ) : (
            <h1>{product.name}</h1>
          )}

          <span>{product.description}</span>
        </DescriptionProduct>

        <Footer>

          <button onClick={closeProductModal} type="button">
            Continuar


          </button>

        </Footer>

      </Content>
    </Container>
  );
};

export default ProductDetails;
