import React, { useEffect, useRef, useState } from 'react';
import { ButtonsContainer, CancelButton, DropdownContainer, TextRegular, TextSemiBold, Wrapper } from './styles';
import ConfirmCancelComponent from './ConfirmCancelation';
import ModalComponent from '../ModalComponent'

const CancelationComponent = ({ product, order_basket_id, order_id, setCancelationModalVisible, fetchData }) => {
  const [amountArray, setAmountArray] = useState([{ label: '1', value: '1' }]);
  const [amountValue, setAmountValue] = useState('1');
  const [confirmCancelModal, setConfirmCancelModal] = useState(false);
  
  const handleOpenConfirmCancelModal = () => {
    setConfirmCancelModal(true);
  };

  useEffect(() => {
    if (product && product.amount > 1) {
      const amountArr = [];
      for (let i = 1; i <= product.amount; i++) {
        amountArr.push({ label: i.toString(), value: i.toString() });
      }
      setAmountArray(amountArr);
    }
  }, []);

  return (
    <>
      <ModalComponent overlayTouchable={true} modalVisible={confirmCancelModal} setModalVisible={setConfirmCancelModal} title="Confirme cancelamento">
        <ConfirmCancelComponent setCancelationModalVisible={setCancelationModalVisible} setModalVisible={setConfirmCancelModal} order_basket_id={order_basket_id} order_id={order_id} new_amount={product.amount - Number(amountValue)} fetchData={fetchData} />
      </ModalComponent>
      <Wrapper>
        <TextRegular style={{ margin: 0 }}>Selecione a quantidade a ser cancelada</TextRegular>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <TextSemiBold style={{ margin: 0 }}>Item(s)</TextSemiBold>
          {product.amount > 0 && product.product && product.product.name && (
            <TextRegular style={{ margin: 0 }}>{product.amount.toString()}x {product.product.name}</TextRegular>
          )}
          <ButtonsContainer>
            <div style={{ flex: 1 }}>
              <DropdownContainer name='amount' id='amount-value' onChange={(e) => setAmountValue(e.target.value)}>
                {amountArray.map((amount) => (
                  <option key={amount.value} value={amount.value}>{amount.label}</option>
                ))}
              </DropdownContainer>
            </div>
            <div style={{ flex: 1 }}>
              <CancelButton onClick={handleOpenConfirmCancelModal}>Cancelar</CancelButton>
            </div>
          </ButtonsContainer>
        </div>
      </Wrapper>
    </>
  );
};

export default CancelationComponent;