import React, { useEffect, useState } from 'react';
import { Wrapper, TextMedium, Input, ButtonContainer, CancelButton, ConfirmButton, ConfirmText } from './styles';
import api from '../../../services/api';
import { useAuth } from '../../../context/auth';

const ConfirmCancelComponent = ({ setModalVisible, order_id, order_basket_id, new_amount, setCancelationModalVisible, fetchData }) => {
  const { userToken } = useAuth();

  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [password, setPassword] = useState('');
  const [justification, setJustification] = useState('');
  const [orderBasketAmount, setOrderBasketAmount] = useState(null);

  useEffect(() => {
    api.get(`waiter/basket-orders/${order_basket_id}`, {
      headers: {
        'Authorization': `Bearer ${userToken}`,
      },
    }).then((response) => {
      console.log('asdf', response.data)
      setOrderBasketAmount(response.data.length);
      setConfirmDisabled(false);
    }).catch((error) => {
      console.error('Error fetching order basket amount:', error);
      alert('Erro ao buscar quantidade de itens do pedido\nPor favor, tente novamente');
    });
  }, [order_basket_id, userToken]);

  const confirmCancel = async () => {
    setConfirmDisabled(true);
    if (new_amount === 0) {
      if (orderBasketAmount === 1) {
        api.put(`waiter/orders/cancel/${order_basket_id}`, {
          order_cancel_password: password,
          cancel_reason: justification,
          from_gm: true,
        }, {
          headers: {
            'Authorization': `Bearer ${userToken}`,
          },
        }).then((response) => {
          console.log('response', 'Item cancelado com sucesso');
          fetchData();
          setModalVisible(false);
          setCancelationModalVisible(false);
        }).catch((error) => {
          console.log('Error canceling item:', error);
          if (error.response.data.message) {
            alert(`Erro: ${error.response.data.message}`);
          } else {
            alert('Erro: Algum erro inesperado ocorreu ao cancelar o item');
          }
        }).finally(() => {
          setConfirmDisabled(false);
        });
      } else if (orderBasketAmount > 1) {
        api.delete(`waiter/orders/basket/${order_basket_id}/item/${order_id}`, {
          headers: {
            'Authorization': `Bearer ${userToken}`,
          },
          data: {
            order_cancel_password: password,
            cancel_reason: justification,
            from_gm: true,
          },
        }).then((response) => {
          console.log('response', 'Item cancelado com sucesso');
          fetchData();
          setModalVisible(false);
          setCancelationModalVisible(false);
        }).catch((error) => {
          console.log('Error canceling item:', error);
          if (error.response.data.message) {
            alert(`Erro: ${error.response.data.message}`);
          } else {
            alert('Erro: Algum erro inesperado ocorreu ao cancelar o item');
          }
        }).finally(() => {
          setConfirmDisabled(false);
        });
      }

    } else if (new_amount > 0) {
      api.put(`waiter/orders/basket/${order_basket_id}/item/${order_id}/${new_amount}`, {
        order_cancel_password: password,
        cancel_reason: justification,
        from_gm: true,
      }, {
        headers: {
          'Authorization': `Bearer ${userToken}`,
        },
      }).then((response) => {
        console.log('response', 'Item cancelado com sucesso');
        fetchData();
        setModalVisible(false);
        setCancelationModalVisible(false);
      }).catch((error) => {
        console.log('Error canceling item:', error);
        if (error.response.data.message) {
          alert(`Erro: ${error.response.data.message}`);
          } else {
            alert('Erro: Algum erro inesperado ocorreu ao cancelar o item');
        }
      }).finally(() => {
        setConfirmDisabled(false);
      });
    } else {
      setConfirmDisabled(false);
      alert('Erro: Quantidade inválida');
    }
  };

  return (
    <Wrapper>
      <div>
        <TextMedium>Senha de cancelamento</TextMedium>
        <Input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div>
        <TextMedium>Justificativa</TextMedium>
        <Input
          value={justification}
          onChange={(e) => setJustification(e.target.value)}
        />
      </div>
      <ButtonContainer>
        <CancelButton onClick={() => setModalVisible(false)}>
          <TextMedium>Sair</TextMedium>
        </CancelButton>
        <ConfirmButton disabled={confirmDisabled} onClick={confirmCancel}>
          <ConfirmText>Confirmar</ConfirmText>
        </ConfirmButton>
      </ButtonContainer>
    </Wrapper>
  );
};

export default ConfirmCancelComponent;
