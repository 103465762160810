import styled, {css} from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.footer`
  width: 100%;
  max-width: 410px;
  height: 75px;
  background: #fff;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #33333310;
`;

export const OrdersPendingBadge = styled.div`
  position: absolute;
  top: -10px;
  left: 49px;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  background: #ff2b3a;
  width: 22px;
  height: 22px;
  color: #fff;
  /* border: 1px solid rgba(251, 74, 32, 1); */
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const ButtonAction = styled.div`
  width: 100%;
  max-width: 410px;
  height: 75px;
  max-width: 410px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;

`;

export const ButtonOption = styled(Link)`
  width: 63px;
  height:  57px;
  text-decoration: none;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #333;
  border: 1px solid #ff2b3a;
  position: relative;
  font-size: 10px;
  border-radius: 7px;
  cursor: pointer;

  svg {
    margin-bottom: 4px;

    ${item =>
      item.path === '/orderspending' && item.path === item.name &&
      css`
        stroke: #fff;

      `}

      ${item =>
      item.path === '/ordersclosed' && item.path === item.name &&  css`
        stroke: #fff;
      `}


      ${item =>
      item.path === '/restauranttables' && item.path === item.name &&
      css`
        stroke: #fff;
      `}

      ${item =>
      item.path === '/paymentspix' && item.path === item.name &&
      css`
        stroke: #fff;
      `}

      ${item =>
      item.path === '/waitertickets' && item.path === item.name &&
      css`
        stroke: #fff;
      `}
  }



  ${item =>
    item.path === '/paymentspix' && item.path === item.name &&
    css`
      background: #ff2b3a;
      border-radius: 7px;
      color: #fff;
    `}


  ${item =>
    item.path === '/orderspending' && item.path === item.name &&
    css`
      background: #ff2b3a;
      border-radius: 7px;
      color: #fff;
    `}

    ${item =>
    item.path === '/ordersclosed' && item.path === item.name &&
    css`
      background: #ff2b3a;
      border-radius: 7px;
      color: #fff;
    `}

    ${item =>
    item.path === '/restauranttables'
    && item.path === item.name &&
    css`
      background: #ff2b3a;
      border-radius: 7px;
      color: #fff;
    `}

    ${item =>
    item.path === '/waitertickets'
    && item.path === item.name &&
    css`
      background: #ff2b3a;
      border-radius: 7px;
      color: #fff;
    `}
`;
