import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import Login from '../pages/Login';
import Menu from '../pages/Menu';
import Cart from '../pages/Cart';
import CloseAccount from '../pages/CloseAccount';
import Product from '../pages/Product';
import ConfirmOrder from '../pages/ConfirmOrder';
import RestaurantTables from '../pages/RestaurantTables';
import OrdersPending from '../pages/OrdersPending';
import OrdersClosed from '../pages/OrdersClosed';
import PaymentsPix from '../pages/PaymentsPix'
import WaiterTickets from '../pages/WaiterTickets'
import WaitingPayment from '../pages/WaitingPayment';
import { CartProvider } from '../context/cart';
import { OrderProvider } from '../context/order';
import { AuthProvider } from '../context/auth';
import { MenuProvider } from '../context/menu';
import { WaiterTicketProvider } from '../context/waiterTicketContext';
import { OrdersProvider } from '../context/ordersContext';
import { TablesProvider } from '../context/tablesContext';
import { PosProvider } from '../context/posContext';


const Routes = () => {
  return (
    <Switch>
      <AuthProvider>
        <PosProvider>
        <TablesProvider>
        <OrdersProvider>
          <WaiterTicketProvider>
            <MenuProvider>
              <OrderProvider>
                  <CartProvider>
                    <Route path="/" exact component={Login} />
                    <Route path="/orderspending" isPrivate component={OrdersPending} />
                    <Route path="/ordersclosed" isPrivate component={OrdersClosed} />
                    <Route path="/menu" isPrivate component={Menu} exact/>
                    <Route
                      path="/restauranttables"
                      isPrivate
                      component={RestaurantTables}
                    />
                    <Route path="/cart" isPrivate component={Cart} />
                    <Route path="/closeaccount" isPrivate component={CloseAccount} />
                    <Route path="/product" isPrivate component={Product}/>
                    <Route path="/confirmorder" isPrivate component={ConfirmOrder} />
                    <Route path="/waitingpayment" isPrivate component={WaitingPayment} />
                    <Route path="/paymentspix" isPrivate component={PaymentsPix} />
                    <Route path="/waitertickets" isPrivate component={WaiterTickets} />
                  </CartProvider>
              </OrderProvider>

            </MenuProvider>
          </WaiterTicketProvider>
        </OrdersProvider>
        </TablesProvider>
        </PosProvider>
      </AuthProvider>
    </Switch>
  );
};

export default Routes;
