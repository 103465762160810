import React, { useState } from 'react';
import { FiEye, FiSmartphone, FiCheckSquare, FiXCircle } from 'react-icons/fi';
import { MdOutlineCancel } from 'react-icons/md';
import { Container, Content, ItemList, Table, Client, Value, Paid, Action } from './styles';
import formatCompleteDate from '../../../services/formatCompleteDate'
import { useCallback } from 'react';
import api from '../../../services/api';
import { AiOutlinePrinter } from 'react-icons/ai';

function PaymentsPosItem({transaction, getPaymentsPos, isFirst}) {
  const [loadingPrint, setLoadingPrint] = useState(false);

  const cancelPayment = useCallback(async()=>{
    if(window.confirm('Tem certeza que deseja cancelar essa transação?')){
      await api.post(`waiter/pos/cancel`, {
        transaction_id: transaction.transaction_id
      });
  
      getPaymentsPos();
    }
  }, [transaction]);

  const resendPrint = useCallback(
    async () => {
      if(!loadingPrint){
        setLoadingPrint(true);
        try{
          await api.post(`waiter/pos/resend-cupom/${transaction.transaction_id}`);
          window.alert('Reenviado para impressão!');
        }catch(err){
          console.log(err);
        }
        setLoadingPrint(false);
      }
    },
    [transaction, loadingPrint],
  );

  function getStatus(status){
    switch(status){
      case 'pending':
        return 'Pendente';
      case 'paid':
        return 'Pago';
      case 'canceled':
        return 'Cancelado';
      case 'closed':
        return 'Finalizado';
    }
  }

  return (
    <Container>
      <Content>
        <ItemList>
          <Table>POS</Table>
          <Client>{getStatus(transaction.status)}</Client>
          <Value>{`R$ ${parseFloat(transaction?.amount) / 100}`}</Value>
          <Paid>{transaction?.createdAt && formatCompleteDate(transaction?.createdAt)}</Paid>
          <Action>
            {isFirst && transaction.status !== 'canceled' && <AiOutlinePrinter size={16} color='orange' onClick={resendPrint} style={{marginRight: 5}}/>}
            {transaction.status === 'pending' && <MdOutlineCancel size={16} color='red' onClick={cancelPayment}/>}
          </Action>
        </ItemList>
      </Content>

    </Container>
  )
}

export default PaymentsPosItem;
