import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import Modal from 'react-modal';
import InputMask from 'react-input-mask';
import { Form } from '@unform/web';
import { LoadingPage } from 'ui-kit-takeat';

import * as Yup from 'yup';

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  Footer,
  HeaderAction,
  RouterDomLink,
  ItemListHeader,
  CancelButton,
  ModalFooter,
  ConfirmButton,
} from './styles';

import formatValue from '../../utils/formatValue';
import MenuItemList from './MenuItemList';

import Input from '../../components/Input';
import BuyersScrollNav from '../../components/BuyersScrollNav';

import api from '../../services/api';
import getValidationErrors from '../../services/getValidationErrors';

import { useCart } from '../../context/cart';
import { useAuth } from '../../context/auth';
import { ModalBody } from 'reactstrap';

const Cart = () => {
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
      border: 'none',
    },
  };


  const customStylesErrorCommand = {
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
      zIndex: 2
    },
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
      border: 'none',
    },
  };

  const {
    tableStatus,
    isBalcony,
    isPos,
    restaurantUseCommand,
    setBuyerCreatedByWaiter,
    sessionKey,
    setBuyersCreatedByWaiter,
    buyersCreatedByWaiter,
    restaurantName,
  } = useAuth();

  const { cart, addCartToOrder, addCartToOrderBalcony, addCartToOrderPos } = useCart();

  const [buyers, setBuyers] = useState([
    { name: '+ Cliente', id: -33801 },
    { name: 'Mesa', id: -33800 },
  ]);
  const [makingOrder, setMakingOrder] = useState(false);
  const [commandTableNumber, setCommandTableNumber] = useState('');
  const [isModalCreateBuyerOpened, setIsModalCreateBuyerOpened] = useState(
    false,
  );
  const [newBuyer, setNewBuyer] = useState(false);
  const formRef = useRef(null);
  const history = useHistory();
  const [requireTableNumber, setRequireTableNumber] = useState(true);

  useEffect(() => {
    api.get(`/public/require-table-number/${restaurantName}`).then(response => {
      setRequireTableNumber(response.data.require_table_number)
    }).catch(error => {
      console.log('error', error)
    })
  }, [restaurantName, setRequireTableNumber])

  function toggleModalCreateBuyer() {
    setIsModalCreateBuyerOpened(!isModalCreateBuyerOpened);
  }

  const totalProductPrice = cart.reduce((TotalAccumulator, order) => {
    const totalCategories = order.complement_categories.reduce(
      (categoryAccumulator, category) => {
        if (category.more_expensive_only) {
          let totalComplements = 0;
          category.complements.forEach(complement => {
            if (
              totalComplements <
              parseFloat(complement.price) *
              (parseInt(complement.amount) >= 1 ? 1 : 0)
            ) {
              totalComplements =
                parseFloat(complement.price) *
                (parseInt(complement.amount) >= 1 ? 1 : 0);
            }
          });
          return categoryAccumulator + totalComplements;
        }

        if (category.use_average) {
          const amountAverage = category.complements.reduce(
            (accum, curr) => accum + curr.amount,
            0
          );

          const totalPriceAverage =
            category.complements
              .map(item => item)
              .reduce(
                (acum, curr) =>
                  acum + (curr.delivery_price || curr.price) * curr.amount,
                0
              ) / amountAverage;

          return totalPriceAverage + categoryAccumulator;
        }

        const totalComplements = category.complements.reduce(
          (complementAccumulator, complement) => {
            return complementAccumulator + complement.price * complement.amount;
          },
          0,
        );
        return categoryAccumulator + totalComplements;
      },
      0
    );

    if (order.weight) {
      return (
        TotalAccumulator +
        (parseFloat(totalCategories) + parseFloat(order.price * order.weight)) *
        order.amount
      );
    }
    return (
      TotalAccumulator +
      (parseFloat(totalCategories) + parseFloat(order.price)) * order.amount
    );
  }, 0);

  function handleMakeAOrder() {
    if (
      restaurantUseCommand.toString() === 'true' &&
      requireTableNumber &&
      commandTableNumber === ''
    ) {
      alert('Informar o número ou local da mesa!')
      return;
    }
    if (cart.length > 0) {
      setMakingOrder(true);
      addCartToOrder(commandTableNumber);

      // closeCart();
    } else {
      alert(
        'O carrinho está vazio, favor adicionar produtos para efetuar o pedido'
      );

      history.goBack();
    }
  }

  function handleChangeCommandTableNumber(e) {
    setCommandTableNumber(e.target.value);
  }

  function handleMakeAOrderBalcony() {
    if (cart.length > 0) {
      setMakingOrder(true);
      addCartToOrderBalcony();
      // closeCart();
    } else {
      alert(
        'O carrinho está vazio, favor adicionar produtos para efetuar o pedido'
      );

      history.goBack();
    }
  }

  function handleMakeAOrderPos(type) {
    if (cart.length > 0) {
      setMakingOrder(true);
      addCartToOrderPos(type);
      // closeCart();
    } else {
      alert(
        'O carrinho está vazio, favor adicionar produtos para efetuar o pedido'
      );

      history.goBack();
    }
  }

  useEffect(() => {
    async function fetchData() {
      const response = await api.get('waiter/session/buyers', {
        params: { session_key: sessionKey },
      });

      const buyer = response.data;

      setBuyers(state => {
        return [...state, ...buyer];
      });
    }

    if (tableStatus === 'open') {
      if (!buyersCreatedByWaiter) {
        fetchData();
      } else {
        setBuyers(buyersCreatedByWaiter);
      }
    } else if (buyersCreatedByWaiter) {
      setBuyers(buyersCreatedByWaiter);
    }
  }, [sessionKey, tableStatus, buyersCreatedByWaiter]);

  /* useEffect(() => { */
  /*   if (isBalcony.toString() === 'false' && isPos.toString() === 'false') { */
  /*     const make_order_button = document.getElementById('make_order_button'); */
  /**/
  /*     if (restaurantUseCommand.toString() === 'true' && commandTableNumber === '') { */
  /*       make_order_button.disabled = true; */
  /*     } else { */
  /*       make_order_button.disabled = false; */
  /*     } */
  /*   } */
  /* }, [restaurantUseCommand, commandTableNumber, isBalcony, isPos]); */

  function handleCreateBuyer() {
    toggleModalCreateBuyer();
  }

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string()
          .required('Usuário obrigatório')
          .min(3, 'Mínimo de 3 letras'),
        phone: Yup.string().matches(/\(\d\d\)\s\d{5}-\d{4}/, {
          message: 'Preencha todos os 9 números',
          excludeEmptyString: true,
        }),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (restaurantUseCommand.toString() === 'true') {
        if (buyers.length === 3) {
          toggleModalErrorCommand()

        } else {
          setBuyerCreatedByWaiter({
            name: data.name,
            phone: data.phone,
          });

          const newBuyers = [...buyers];

          newBuyers.splice(2, 0, {
            name: data.name,
            phone: data.phone,
            id: -33802,
          });

          setBuyers(newBuyers);

          setNewBuyer(true);

          setBuyersCreatedByWaiter(newBuyers);

          toggleModalCreateBuyer();
        }
      } else {
        setBuyerCreatedByWaiter({
          name: data.name,
          phone: data.phone,
        });

        const newBuyers = [...buyers];

        newBuyers.splice(2, 0, {
          name: data.name,
          phone: data.phone,
          id: -33802,
        });

        setBuyers(newBuyers);

        setNewBuyer(true);

        setBuyersCreatedByWaiter(newBuyers);

        toggleModalCreateBuyer();
      }


    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
        console.log('@@', data.phone, errors);
      } else {
        alert('Falha ao criar usuário...');
      }
    }
  }

  const [isModalErrorCommandOpened, setIsModalErrorCommandOpened] = useState(false)

  function toggleModalErrorCommand() {
    setIsModalErrorCommandOpened(!isModalErrorCommandOpened)
  }

  return makingOrder ? (
    <LoadingPage duration={3} text="Fazendo seu pedido..." />
  ) : (
    <Container>
      <Modal
        isOpen={isModalErrorCommandOpened}
        onRequestClose={toggleModalErrorCommand}
        style={customStylesErrorCommand}
      >

        <ModalBody>
          <p style={{ fontWeight: "bold" }}>Mesa do tipo comanda só pode ter 1 usuário adicionado.</p>
        </ModalBody>
        <ModalFooter>
          <ConfirmButton onClick={toggleModalErrorCommand}>Entendi</ConfirmButton>

        </ModalFooter>

      </Modal>
      <Modal
        isOpen={isModalCreateBuyerOpened}
        onRequestClose={toggleModalCreateBuyer}
        style={customStyles}
      >
        <Form onSubmit={handleSubmit} ref={formRef}>
          <Input name="name" label="Nome do cliente:" />
          <InputMask mask="(99) 99999-9999" maskChar="">
            {() => (
              <Input name="phone" label="Celular:" placeholder="(opcional)" />
            )}
          </InputMask>

          <ModalFooter>
            <ConfirmButton type="submit">Salvar</ConfirmButton>
            <CancelButton type="button" onClick={toggleModalCreateBuyer}>
              Cancelar
            </CancelButton>
          </ModalFooter>
        </Form>
      </Modal>
      <Content>
        <HeaderAction>
          <header>
            <h1>Carrinho</h1>

            <RouterDomLink to={`/menu`}>
              <FiArrowLeft />
              Continuar comprando
            </RouterDomLink>
          </header>
        </HeaderAction>
        {isBalcony.toString() === 'false' && isPos.toString() === 'false' && (
          <>
            <ItemListHeader>
              <div>
                <p>Clientes</p>
              </div>
            </ItemListHeader>
            <BuyersScrollNav buyers={buyers} createBuyer={handleCreateBuyer} newBuyer={newBuyer} />
          </>
        )}
        <ItemListHeader>
          <div>
            <p>Item</p>
          </div>
        </ItemListHeader>
        <MenuContent>
          {isBalcony.toString() === 'false' && isPos.toString() === 'false' && restaurantUseCommand.toString() === 'true' &&
            <InputMask
              placeholder={requireTableNumber ? "Nº da Mesa (Obrigatório)" : "Nº da Mesa"}
              value={commandTableNumber}
              onChange={e => handleChangeCommandTableNumber(e)}
              style={{ marginTop: 8, width: 200 }}
              maxLength={20}
            />
          }
          <MenuItem>
            {cart.map(item => (
              <MenuItemList item={item} key={item.basket_id} />
            ))}
          </MenuItem>
        </MenuContent>
        {
          isPos.toString() === 'true' &&
          <div style={{ marginTop: 15 }}>
            <p><strong>Total: </strong> {formatValue(Number(totalProductPrice))}</p>
          </div>
        }

        <Footer>
          {isBalcony.toString() === 'true' ? (
            <div>
              <button onClick={handleMakeAOrderBalcony}>
                {`Fazer Pedido ${formatValue(Number(totalProductPrice))}`}
              </button>
            </div>
          ) : (
            isPos.toString() === 'true' ?
              (<div>
                <button onClick={e => handleMakeAOrderPos('credit')} >
                  {`Crédito`}
                </button>
                <button onClick={e => handleMakeAOrderPos('debit')} >
                  {`Débito`}
                </button>
              </div>)
              :
              (
                <div>
                  <button className="btn-primary"
                    id="make_order_button"
                    onClick={handleMakeAOrder}
                  >
                    {`Fazer Pedido ${formatValue(Number(totalProductPrice))}`}
                  </button>
                </div>
              )
          )}
        </Footer>
      </Content>
    </Container>
  );
};

export default Cart;
