import React, { useState, useEffect, useRef } from 'react';
import {
  FiMessageSquare,
  FiAlertCircle,
  FiX,
  FiSearch
} from 'react-icons/fi';

import {
  FaExclamationCircle,
  FaMinusCircle,
  FaPlusCircle,

} from 'react-icons/fa';
import { useCart } from '../../context/cart';
import { useAuth } from '../../context/auth';
import fotoPadrao from '../../assets/images/noImage.png';

import ScrollToTop from '../../components/ScrollToTop';
import {
  Container,
  Content,
  ImageProduct,
  DescriptionProduct,
  AddItemsProduct,
  EditAction,
  EditActionButtons,
  ComplementsHeaderProduct,
  ComplementItem,
  ComplementItemDescription,
  ComplementItemAmount,
  InformationText,
  ConfirmButton,
  ModalTitle,
  ModalFooter,
  Footer,
  CloseButton,
  QuestionSubtitle,
  ProductName,
  ModalStyled,
  SearchBar,
  ButtonDeleteInput,
  ButtonSearchInput
} from './styles';
import formatValue from '../../utils/formatValue';


const Product = ({ product, closeFullProductModal, countProducts }) => {
  const { addToCart } = useCart();

  const {
    tableType,
  } = useAuth();

  // const location = useLocation();
  // const { product } = location.state;


  const [observation, setObservation] = useState('');
  const [amount, setAmount] = useState(1);
  const [modalPhrase, setModalPhrase] = useState('');
  const [modalAction, setModalAction] = useState([]);
  const [complementsCategory, setComplementsCategory] = useState([]);
  const [mandatoryComplements, setMandatoryComplements] = useState([]);
  const [isProductWeight, setIsProductWeight] = useState(product?.use_weight);
  const [inputProductWeight, setInputProductWeight] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [complementsFiltered, setComplementsFiltered] = useState([]);


  function adcFilter(key, name) {
    const newFilter = { id: key, nome: name };
    const filtroExistente = complementsFiltered.find(filtro => filtro.id === key);

    if (filtroExistente) {
      const novoFiltro = complementsFiltered.map(filtro =>
        filtro.id === key ? newFilter : filtro
      );
      setComplementsFiltered(novoFiltro);
    } else {
      const novoFiltro = [...complementsFiltered, newFilter];
      setComplementsFiltered(novoFiltro);
    }
  }

  function deleteValue(complement) {
    const inputElement = document.getElementById(complement?.name);
    inputElement.value = '';
    adcFilter(complement.id, '')
  }

  const inputComplementRef = useRef(null);

  function toggleModal() {
    setIsOpen(!modalIsOpen);
  }

  function closeModal() {
    setIsOpen(false);

    if (modalAction.length > 0) {
      modalAction[0]();
    }
  }


  function openModal(phrase) {
    setIsOpen(true);
    setModalPhrase(phrase);
  }

  useEffect(() => {
    const mandatoryPDV = product.complement_categories.filter(item => item.available).filter(
      item =>
        item.complements.filter(compl => compl.available).length > 0 &&
        item.optional === false,
    );
    setMandatoryComplements(mandatoryPDV);

  }, [product?.complement_categories, tableType]);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const res = await api.get('client/bills', {
  //         params: { session_key: sessionKey },
  //       });

  //       setTableNumber(res.data.session.table.table_number);
  //     } catch (err) {
  //       const { error } = err.response.data;

  //       if (err.response.status === 400) {
  //         console.log(error);
  //         openModal('Sua conta já foi fechada');
  //         setModalAction([
  //           () => {
  //             logOut();
  //             history.push('/');
  //           },
  //         ]);
  //       }
  //     }
  //   }
  //   if (sessionKey && tableType !== 'delivery') {
  //     fetchData();
  //   }
  // }, [sessionKey, history, logOut]);

  function handleAddObservation(e) {
    setObservation(e.target.value);
  }

  function increment() {
    setAmount(amount + 1);
  }

  function decrement() {
    setAmount(amount - 1);
  }

  function handleAddComplements(
    id,
    name,
    price,
    delivery_price,
    limit,
    categoryLimit,
    categoryId,
    categoryAdditional,
    categoryMoreExpensiveOnly,
    minimum,
    useAverage
  ) {
    const categoryFind = mandatoryComplements.findIndex(
      category => category.id === categoryId,
    );
    if (categoryFind >= 0) {
      const complementFind = mandatoryComplements[
        categoryFind
      ].complements.findIndex(item => item.id === id);

      if (complementFind >= 0) {
        mandatoryComplements[categoryFind].complements[
          complementFind
        ].checkAmount += 1;
      }
    }

    const newCategoryExist = [...complementsCategory];
    const categoryExist = newCategoryExist.findIndex(
      category => category.id === categoryId,
    );

    if (categoryExist >= 0) {
      const categoryTotalAmount = complementsCategory[
        categoryExist
      ].complements.reduce((acum, curr) => acum + curr.amount, 0);

      const complementExist = newCategoryExist[
        categoryExist
      ].complements.findIndex(complement => complement.id === id);

      if (complementExist >= 0) {
        if (categoryTotalAmount < categoryLimit) {
          if (
            newCategoryExist[categoryExist].complements[complementExist]
              .amount < limit
          ) {
            newCategoryExist[categoryExist].complements[
              complementExist
            ].amount += 1;
            newCategoryExist[categoryExist].minimum = minimum;
          } else {
            openModal(
              'Você só pode adicionar outro complemento ao seu lanche, pois o limite deste complemento foi alcançado.',
            );
          }
        } else {
          openModal('Limite de catgoria');
        }
      } else {
        newCategoryExist[categoryExist].complements.push({
          id,
          name,
          price,
          delivery_price,
          amount: 1,
        });
        newCategoryExist[categoryExist].minimum = minimum;
      }

      setComplementsCategory(newCategoryExist);
    } else {
      setComplementsCategory([
        ...complementsCategory,
        {
          id: categoryId,
          additional: categoryAdditional,
          more_expensive_only: categoryMoreExpensiveOnly,
          complements: [{ id, name, price, delivery_price, amount: 1 }],
          minimum,
          use_average: useAverage
        },
      ]);
    }
  }

  function handleRemoveComplements(categoryId, complementId) {
    const categoryFind = mandatoryComplements.findIndex(
      category => category.id === categoryId,
    );
    if (categoryFind >= 0) {
      const complementFind = mandatoryComplements[
        categoryFind
      ].complements.findIndex(item => item.id === complementId);

      if (complementFind >= 0) {
        mandatoryComplements[categoryFind].complements[
          complementFind
        ].checkAmount -= 1;
      }
    }
    const newCategoryExist = [...complementsCategory];
    const categoryExistId = newCategoryExist.findIndex(
      category => category.id === categoryId,
    );

    if (categoryExistId >= 0) {
      const complementExist = newCategoryExist[
        categoryExistId
      ].complements.findIndex(complement => complement.id === complementId);

      if (
        newCategoryExist[categoryExistId].complements[complementExist].amount >
        0
      ) {
        newCategoryExist[categoryExistId].complements[
          complementExist
        ].amount -= 1;

        setComplementsCategory(newCategoryExist);
      } else {
        const filteredComplements = newCategoryExist[
          categoryExistId
        ].complements.filter(complement => complement.id !== complementId);

        setComplementsCategory(filteredComplements);
      }
    }
  }

  let complementMoreExpensive = 0;
  complementsCategory
    .filter(category => category.more_expensive_only === true)
    .forEach(item => {
      let local_price = 0;
      item.complements.forEach(complement => {
        if (local_price < parseFloat(complement.price) * (parseInt(complement.amount) >= 1 ? 1 : 0)) {
          local_price = parseFloat(complement.price) * (parseInt(complement.amount) >= 1 ? 1 : 0);
        }
      });
      complementMoreExpensive += local_price;
    });

  const totalComplementsPrice =
    tableType === 'delivery'
      ? complementsCategory
        .filter(
          category =>
            category.additional === true &&
            category.more_expensive_only === false,
        )
        .map(complementCat => {
          if (complementCat.use_average) {
            const amountAverage = complementCat.complements.reduce(
              (accum, curr) => accum + curr.amount,
              0,
            );

            const totalPriceAverage =
              complementCat.complements
                .map(item => item)
                .reduce(
                  (acum, curr) =>
                    acum + (curr.delivery_price || curr.price) * curr.amount,
                  0,
                ) / amountAverage;

            return totalPriceAverage > 0 ? Number(totalPriceAverage) : 0;
          }
          return complementCat.complements
            .map(item => item)
            .reduce(
              (acum, curr) =>
                acum + (curr.delivery_price || curr.price) * curr.amount,
              0,
            );
        })
        .reduce((acum, curr) => acum + curr, 0) + complementMoreExpensive
      : complementsCategory
        .filter(
          category =>
            category.additional === true &&
            category.more_expensive_only === false,
        )

        .map(complementCat => {
          if (complementCat.use_average) {
            const amountAverage = complementCat.complements.reduce(
              (accum, curr) => accum + curr.amount,
              0,
            );

            const totalPriceAverage =
              complementCat.complements
                .map(item => item)
                .reduce((acum, curr) => acum + curr.price * curr.amount, 0) /
              amountAverage;

            return totalPriceAverage > 0 ? Number(totalPriceAverage) : 0;
          }
          return complementCat.complements
            .map(item => item)
            .reduce((acum, curr) => acum + curr.price * curr.amount, 0);
        })
        .reduce((acum, curr) => acum + curr, 0) + complementMoreExpensive;

  function totalCategoryComplementsAmount(categoryId) {
    const total = complementsCategory
      .filter(category => category.id === categoryId)
      .map(item =>
        item.complements.reduce((acum, curr) => acum + curr.amount, 0),
      );

    return total;
  }

  const check = [];

  for (const cat of mandatoryComplements) {
    const category = cat.complements.reduce(
      (acum, curr) => acum + curr.checkAmount,
      0,
    );

    check.push(category);
  }

  function deleteCheckAmount() {
    mandatoryComplements.map(item =>
      item.complements.map(compl => (compl.checkAmount = 0)),
    );
  }

  function addToCarts() {
    if (isProductWeight) {
      if (inputProductWeight) {
        if (mandatoryComplements.length > 0) {
          const checkedMandatoryComplements = check.reduce(
            (acum, curr) => acum && curr > 0,
          );

          const hasMinimum = complementsCategory.filter(
            item => item.minimum > 0,
          );

          const minimumCheck = complementsCategory.map(category => {
            const complementsAmountSum = category.complements.reduce(
              (acum, curr) => curr.amount + acum,
              0,
            );

            return category.minimum > 1
              ? category.minimum <= complementsAmountSum
              : true;
          });

          if (hasMinimum) {
            if (minimumCheck.every(item => item === true)) {
              if (checkedMandatoryComplements) {
                addToCart({
                  product,
                  amount,
                  observation,
                  complementsCategory,
                  isProductWeight,
                  inputProductWeight,
                });
                closeFullProductModal()
                // history.push(`/menu`);
                // closeProductModal();
                countProducts({ id: product.id, amount: amount })
                deleteCheckAmount();
              } else {
                openModal('Favor adicionar todos os complementos obrigatórios');
              }
            } else {
              openModal('Favor adicionar a quantidade mínima de complementos');
            }
          } else if (checkedMandatoryComplements) {
            addToCart({
              product,
              amount,
              observation,
              complementsCategory,
              isProductWeight,
              inputProductWeight,
            });
            closeFullProductModal()
            // history.push(`/menu`);
            // closeProductModal();
            countProducts({ id: product.id, amount: amount })
            deleteCheckAmount();
          } else {
            openModal('Favor adicionar todos os complementos obrigatórios');
          }
        } else {
          const hasMinimum = complementsCategory.filter(
            item => item.minimum > 1,
          );

          const minimumCheck = complementsCategory.map(category => {
            const complementsAmountSum = category.complements.reduce(
              (acum, curr) => curr.amount + acum,
              0,
            );

            return category.minimum > 1
              ? category.minimum <= complementsAmountSum
              : true;
          });

          if (hasMinimum) {
            if (minimumCheck.every(item => item === true)) {
              addToCart({
                product,
                amount,
                observation,
                complementsCategory,
                isProductWeight,
                inputProductWeight,
              });
              closeFullProductModal()
              // history.push(`/menu`);
              // closeProductModal();
              countProducts({ id: product.id, amount: amount })
              deleteCheckAmount();
            } else {
              openModal('Favor adicionar a quantidade mínima de complementos');
            }
          } else {
            addToCart({
              product,
              amount,
              observation,
              complementsCategory,
              isProductWeight,
              inputProductWeight,
            });
            closeFullProductModal()

            // history.push(`/menu`);
            // closeProductModal();
            countProducts({ id: product.id, amount: amount })
            deleteCheckAmount();
          }
        }
      } else {
        openModal('Favor informar o peso do produto.');
      }
    } else if (mandatoryComplements.length > 0) {
      const checkedMandatoryComplements = check.reduce(
        (acum, curr) => acum && curr > 0,
      );

      const hasMinimum = complementsCategory.filter(item => item.minimum > 0);

      const minimumCheck = complementsCategory.map(category => {
        const complementsAmountSum = category.complements.reduce(
          (acum, curr) => curr.amount + acum,
          0,
        );

        return category.minimum > 1
          ? category.minimum <= complementsAmountSum
          : true;
      });

      if (hasMinimum) {
        if (minimumCheck.every(item => item === true)) {
          if (checkedMandatoryComplements) {
            addToCart({
              product,
              amount,
              observation,
              complementsCategory,
            });
            //  history.push(`/menu`);
            countProducts({ id: product.id, amount: amount })
            closeFullProductModal()
            deleteCheckAmount();
            // closeProductModal();
          } else {
            openModal('Favor adicionar todos os complementos obrigatórios');
          }
        } else {
          openModal('Favor adicionar a quantidade mínima de complementos');
        }
      } else if (checkedMandatoryComplements) {
        addToCart({
          product,
          amount,
          observation,
          complementsCategory,
        });
        countProducts({ id: product.id, amount: amount })
        deleteCheckAmount();
      } else {
        openModal('Favor adicionar todos os complementos obrigatórios');
      }
    } else {
      const hasMinimum = complementsCategory.filter(item => item.minimum > 1);

      const minimumCheck = complementsCategory.map(category => {
        const complementsAmountSum = category.complements.reduce(
          (acum, curr) => curr.amount + acum,
          0,
        );

        return category.minimum > 1
          ? category.minimum <= complementsAmountSum
          : true;
      });

      if (hasMinimum) {
        if (minimumCheck.every(item => item === true)) {
          addToCart({
            product,
            amount,
            observation,
            complementsCategory,
          });
          // history.push(`/menu`);
          closeFullProductModal()
          countProducts({ id: product.id, amount: amount })
          deleteCheckAmount();
          // closeProductModal();
        } else {
          openModal('Favor adicionar a quantidade mínima de complementos');
        }
      } else {
        addToCart({
          product,
          amount,
          observation,
          complementsCategory,
        });
        closeFullProductModal()
        // history.push(`/menu`);
        // closeProductModal();
        countProducts({ id: product.id, amount: amount })
        deleteCheckAmount();
      }
    }
  }

  function handleChangeProductWeight(e) {
    setInputProductWeight(e.target.value);
  }


  return (

    <Container>
      <ScrollToTop />
      <Content>
        <ModalStyled
          isOpen={modalIsOpen}
          toggle={toggleModal}
        >
          <ModalTitle>
            <FaExclamationCircle color="#FF2C3A" size={30} />
            <span>{modalPhrase}</span>
            <ConfirmButton onClick={closeModal}>OK</ConfirmButton>
          </ModalTitle>
        </ModalStyled>

        <CloseButton type="button" onClick={closeFullProductModal}>
          <FiX size={20} color="red" />
        </CloseButton>

        {product.image && (
          <ImageProduct>
            <img src={product.image.url_thumb || product.image.url} alt={product.description} />
          </ImageProduct>
        )}

        <DescriptionProduct>
          {!product.image ? (
            <ProductName>{product.name}</ProductName>
          ) : (
            <h1>{product.name}</h1>
          )}

          <span>{product.description}</span>
        </DescriptionProduct>

        <AddItemsProduct>
          <div>
            <EditAction>
              {isProductWeight.toString() === 'true' ? (
                <span>Peso do Produto (kg)</span>
              ) : (
                <span>Quantidade</span>
              )}
              <EditActionButtons>
                {isProductWeight ? (
                  <input
                    type="number"
                    placeholder="ex: 0,800"
                    onChange={e => {
                      handleChangeProductWeight(e);
                    }}
                  />
                ) : (
                  <div style={{ height: '40px', width: '90px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {!product?.is_unitary && (<button onClick={decrement} type="button">
                      {amount > 1 && (
                        <FaMinusCircle size={18} color="#ff2c3a" />
                      )}
                    </button>)}
                    <p>{amount}</p>

                    {!product?.is_unitary && (
                      <button onClick={increment} type="button">
                        <FaPlusCircle size={18} color="#ff2c3a" />
                      </button>
                    )}

                  </div>
                )}
              </EditActionButtons>
            </EditAction>

            {product.complement_categories
              .filter(categ => categ.available)
              .filter(item =>
                item.complements.filter(compl => compl.available).length > 0
              )
              .map(complement => (


                <React.Fragment key={complement && complement.id}>

                  <ComplementsHeaderProduct>
                    <div>
                      <div>{complement.question}</div>
                      {complement.complements.length > 10 && (
                        <SearchBar>
                          <input
                            name="product"
                            type="text"
                            placeholder="Buscar Complemento"
                            style={{ height: 30 }}
                            onChange={(e) => {
                              adcFilter(complement.id, e.target.value)
                            }
                            }
                            id={complement?.name}
                            ref={inputComplementRef}
                          />

                          <ButtonSearchInput

                          >
                            <FiSearch size={14} />
                          </ButtonSearchInput>
                          <ButtonDeleteInput
                            type="button"
                            title="Mostrar todos"
                            onClick={() => {
                              deleteValue(complement);
                            }}
                          >
                            <FiX size={14} />
                          </ButtonDeleteInput>
                        </SearchBar>
                      )}
                      {complement.limit > complement.minimum && (
                        <>
                          {complement.minimum > 1 ? (
                            <QuestionSubtitle>
                              {`Escolha entre ${complement.minimum} e ${complement.limit
                                }
                        ${complement.limit > 1 ? ' itens' : ' item'} no total.`}
                              {complement.more_expensive_only && (
                                <div style={{ marginTop: 10, color: 'red' }}>
                                  Apenas o valor do complemento mais caro será
                                  cobrado
                                </div>
                              )}
                            </QuestionSubtitle>
                          ) : (
                            <QuestionSubtitle>
                              {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                } no total.`}
                              {complement.more_expensive_only && (
                                <div style={{ marginTop: 20, color: 'red' }}>
                                  Apenas o valor do complemento mais caro será
                                  cobrado
                                </div>
                              )}
                            </QuestionSubtitle>
                          )}
                        </>
                      )}

                      {complement.limit < complement.minimum && (
                        <>
                          {complement.minimum > 1 ? (
                            <QuestionSubtitle>
                              {`Escolha ${complement.minimum
                                }                        ${complement.limit > 1 ? ' itens' : ' item'
                                } no mínimo.`}
                              {complement.more_expensive_only && (
                                <div style={{ marginTop: 10, color: 'red' }}>
                                  Apenas o valor do complemento mais caro será
                                  cobrado
                                </div>
                              )}
                            </QuestionSubtitle>
                          ) : (
                            <QuestionSubtitle>
                              {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                } no total.`}
                              {complement.more_expensive_only && (
                                <div style={{ marginTop: 20, color: 'red' }}>
                                  Apenas o valor do complemento mais caro será
                                  cobrado
                                </div>
                              )}
                            </QuestionSubtitle>
                          )}
                        </>
                      )}

                      {complement.limit === complement.minimum && (
                        <>
                          {complement.minimum > 1 ? (
                            <QuestionSubtitle>
                              {`Escolha ${complement.minimum} ${complement.limit > 1 ? ' itens' : ' item'
                                } no total.`}
                              {complement.more_expensive_only && (
                                <div style={{ marginTop: 10, color: 'red' }}>
                                  Apenas o valor do complemento mais caro será
                                  cobrado
                                </div>
                              )}
                            </QuestionSubtitle>
                          ) : (
                            <QuestionSubtitle>
                              {`Escolha até ${complement.limit} ${complement.limit > 1 ? ' itens' : ' item'
                                } no total.`}
                              {complement.more_expensive_only && (
                                <div style={{ marginTop: 20, color: 'red' }}>
                                  Apenas o valor do complemento mais caro será
                                  cobrado
                                </div>
                              )}
                            </QuestionSubtitle>
                          )}
                        </>
                      )}
                    </div>

                    {complement.optional !== true && <p>Obrigatório</p>}
                  </ComplementsHeaderProduct>
                  <ComplementItem>

                    {complement.complements.length > 10 ? (
                      complement.complements
                        .filter(comp => comp.available)
                        .filter(item => {
                          const filterFound = complementsFiltered.find(ft => ft.id === complement.id && ft.nome.length > 3);
                          return filterFound ? item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(filterFound.nome.toLowerCase()) : item;
                        })
                        .map(item => (
                          <li key={item.id}>
                            {item.image && (
                              <img
                                src={item.image.url_thumb || item.image.url || fotoPadrao}
                                alt={item.nome || 'foto'}
                                style={{ width: 75, height: 65, borderRadius: 8 }}
                              />
                            )}
                            <ComplementItemDescription>
                              <span>
                                <strong>{item.name}</strong>
                              </span>
                              {item.description && (
                                <span style={{ fontSize: 15 }}>
                                  {item.description}
                                </span>
                              )}
                              {(Number(item.price) > 0) &
                                (complement.additional === true) ? (
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    fontFamily: 'Arial',
                                  }}
                                >
                                  + {formatValue(item.price)}
                                </span>
                              ) : null}


                            </ComplementItemDescription>

                            <ComplementItemAmount>
                              {complementsCategory
                                .filter(category => category.id === complement.id)
                                .map(cat =>
                                  cat.complements
                                    .filter(
                                      complement => complement.id === item.id,
                                    )
                                    .map(filtered => {
                                      if (filtered.amount >= 1) {
                                        return (
                                          <React.Fragment key={complement.id}>
                                            <FaMinusCircle
                                              size={18}
                                              color="#ff2c3a"
                                              onClick={() => {
                                                handleRemoveComplements(
                                                  complement.id,
                                                  item.id,
                                                );
                                              }}
                                            />
                                            <p
                                              style={{
                                                fontSize: '20px',
                                                marginTop: '8px',
                                              }}
                                            >
                                              {filtered.amount}
                                            </p>
                                          </React.Fragment>
                                        );
                                      }
                                      return '';
                                    }),
                                )}

                              {totalCategoryComplementsAmount(complement.id) <
                                complement.limit && (
                                  <FaPlusCircle
                                    size={18}
                                    color="#ff2c3a"
                                    onClick={() => {
                                      handleAddComplements(
                                        item.id,
                                        item.name,
                                        item.price,
                                        item.delivery_price,
                                        item.limit,
                                        complement.limit,
                                        complement.id,
                                        complement.additional,
                                        complement.more_expensive_only,
                                        complement.minimum,
                                        complement.use_average,
                                      );
                                    }}
                                  />
                                )}
                            </ComplementItemAmount>
                          </li>
                        ))) : (complement.complements
                          .filter(comp => comp.available)
                          .map(item => (
                            <li key={item.id}>
                              {item.image && (
                                <img
                                  src={item.image.url_thumb || item.image.url || fotoPadrao}
                                  alt={item.nome || 'foto'}
                                  style={{ width: 75, height: 65, borderRadius: 8 }}
                                />
                              )}
                              <ComplementItemDescription>
                                <span>
                                  <strong>{item.name}</strong>
                                </span>
                                {item.description && (
                                  <span style={{ fontSize: 15 }}>
                                    {item.description}
                                  </span>
                                )}
                                {(Number(item.price) > 0) &
                                  (complement.additional === true) ? (
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                      fontSize: 12,
                                      fontFamily: 'Arial',
                                    }}
                                  >
                                    + {formatValue(item.price)}
                                  </span>
                                ) : null}


                              </ComplementItemDescription>

                              <ComplementItemAmount>
                                {complementsCategory
                                  .filter(category => category.id === complement.id)
                                  .map(cat =>
                                    cat.complements
                                      .filter(
                                        complement => complement.id === item.id,
                                      )
                                      .map(filtered => {
                                        if (filtered.amount >= 1) {
                                          return (
                                            <React.Fragment key={complement.id}>
                                              <FaMinusCircle
                                                size={18}
                                                color="#ff2c3a"
                                                onClick={() => {
                                                  handleRemoveComplements(
                                                    complement.id,
                                                    item.id,
                                                  );
                                                }}
                                              />
                                              <p
                                                style={{
                                                  fontSize: '20px',
                                                  marginTop: '8px',
                                                }}
                                              >
                                                {filtered.amount}
                                              </p>
                                            </React.Fragment>
                                          );
                                        }
                                        return '';
                                      }),
                                  )}

                                {totalCategoryComplementsAmount(complement.id) <
                                  complement.limit && (
                                    <FaPlusCircle
                                      size={18}
                                      color="#ff2c3a"
                                      onClick={() => {
                                        handleAddComplements(
                                          item.id,
                                          item.name,
                                          item.price,
                                          item.delivery_price,
                                          item.limit,
                                          complement.limit,
                                          complement.id,
                                          complement.additional,
                                          complement.more_expensive_only,
                                          complement.minimum,
                                          complement.use_average,
                                        );
                                      }}
                                    />
                                  )}
                              </ComplementItemAmount>
                            </li>
                          )))}
                  </ComplementItem>
                </React.Fragment>



              ))}

            <InformationText>
              <p>
                <FiMessageSquare color="#ff2c3a" />
                Quer fazer alguma observação?
              </p>
              <input
                placeholder="Ex: adicionar gelo e limão ... "
                value={observation}
                onChange={e => {
                  handleAddObservation(e);
                }}
                maxLength="150"
              />
            </InformationText>
          </div>
        </AddItemsProduct>

        <Footer>
          {product.sold_off ? (
            <button type="button">
              <span>
                <FiAlertCircle size={22} />
              </span>
              Produto Esgotado
            </button>
          ) : (
            <button onClick={addToCarts} type="button">
              Adicionar ao carrinho
              {isProductWeight ? (
                <span>
                  {formatValue(
                    amount *
                    (Number(totalComplementsPrice) +
                      Number(product.price * inputProductWeight)),
                  )}
                </span>
              ) : (
                <span>
                  {formatValue(
                    amount *
                    (Number(totalComplementsPrice) + Number(product.price)),
                  )}
                </span>
              )}
            </button>
          )}
        </Footer>

      </Content>
    </Container>
  );

};

export default Product;
