import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BsClockHistory } from 'react-icons/bs';

import { useAuth } from '../../context/auth';
import Modal from 'react-modal';

import { Container, Content, Message, Footer, ModalContent } from './styles';
import api from '../../services/api';

const WaitingPayment = () => {
  const { basketId, stoneTransactionId } = useAuth();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [printText, setPrintText] = useState('Não imprimiu');

  function handleNavigate() {
    history.push('/restauranttables');
  }

  const validatePayment = useCallback(
    async () => {
      try{
        const response = await api.get(`waiter/pos/validate/${stoneTransactionId}`);

        if(response.data.paid === true){
          handleNavigate();
        }else{
          toggleModalNotPaid();
        }
      }catch(err){
        console.log(err);
      }
    },
    [basketId, stoneTransactionId],
  );

  const resendPrint = useCallback(
    async () => {
      setLoadingPrint(true);
      setPrintText('Aguarde...');
      try{
        await api.post(`waiter/pos/resend-cupom/${stoneTransactionId}`);
      }catch(err){
        console.log(err);
      }
      setLoadingPrint(false);
      setPrintText('Não imprimiu');
    },
    [basketId, stoneTransactionId],
  );

  const [modal, setModal] = useState(false);
  function toggleModal() {
    setModal(!modal);
  }

  const [modalNotPaid, setModalNotPaid] = useState(false);
  function toggleModalNotPaid() {
    setModalNotPaid(!modalNotPaid);
  }

  const handleCancel = useCallback(
    async () => {
      setLoading(true);
      try{
        const response = await api.post('waiter/pos/cancel', {
          basket_id: basketId
        });

        if(response.status === true){
          history.push('/menu');
        }else{
          history.push('/menu');
        }
      }catch(err){
        console.log(err);
      }
      setLoading(false);
    },
    [basketId],
  );

  const customStyles = {
    content: {
      maxWidth: '390px',
      padding: '10px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
      zIndex: '1000',
      alignItems: 'center',
      justifyContent: 'center'
    },
  };

  return (
    <Container>
      <Content>
        <Message>
          <BsClockHistory size={136} color="#ff2c19" />
          <h1 style={{fontWeight: 'bold', textAlign: 'center'}}>Aguardando Pagamento</h1>
          <p style={{marginTop: '20px'}}>
            {`Assim que o pedido for pago, o cupom será impresso.`}
          </p>
        </Message>
        <Footer>
          <div>
            <button onClick={resendPrint} disabled={loadingPrint} style={{color: '#ff3c3a', background: '#fff', border: '1px solid #ff3c3a'}}>
              {printText}
            </button>
            <button onClick={validatePayment} style={{color: '#ff3c3a', background: '#fff', border: '1px solid #ff3c3a'}}>
              Já pago
            </button>
          </div>
          <button onClick={toggleModal}>
            Cancelar
          </button>
        </Footer>
      </Content>

      <Modal isOpen={modal} toggle={toggleModal} style={customStyles} onClosed={toggleModal}>
        <ModalContent> 
          <h4>Tem certeza que deseja cancelar o pedido?</h4>
          <button onClick={handleCancel} disabled={loading}>
            Sim
          </button>
          <button onClick={toggleModal} style={{color: '#ff3c3a', background: '#fff', border: '1px solid #ff3c3a'}}>
            Não
          </button>
        </ModalContent>
      </Modal>

      <Modal isOpen={modalNotPaid} toggle={toggleModalNotPaid} style={customStyles} onClosed={toggleModalNotPaid}>
        <ModalContent> 
          <h4>Pedido ainda não foi pago.</h4>
          <button onClick={toggleModalNotPaid} style={{color: '#ff3c3a', background: '#fff', border: '1px solid #ff3c3a'}}>
            Ok
          </button>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default WaitingPayment;
