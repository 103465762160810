import React from 'react';
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom';

import { useAuth } from '../context/auth';

const Route = ({ isPrivate = false, component: Component, ...rest }) => {
  const { userToken } = useAuth();

  //sessionKey && tableNumber ? redirect to /auto/restaurantName
  //!sessionKey

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return isPrivate === !!userToken ? (
          <Component />
        ) : (
          <Redirect to={{ pathname: isPrivate ? '/' : '/orderspending' }} />
        );
      }}
    />
  );
};

export default Route;
