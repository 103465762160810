import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`

`


export const ItemList = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 7px;
  border-radius: 7px;
  font-size: 12px;
  margin-top: 12px;

  & + & {
    border-top: 1px solid #33333320;
  }


`

export const Table = styled.span``
export const Client = styled.span``
export const Value = styled.span``
export const Paid = styled.span``
export const Action = styled.span``
