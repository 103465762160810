import styled from 'styled-components';

export const AddToCart = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const MenuItemBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  & + div {
    padding-top: 20px;
    border-top: 1px solid #33333310;
  }
`;

export const MenuItemDescription = styled.div`
  width: 110px;
  margin-left: 8px;
  font-size: 14px;
  color: #333;
  strong {
    font-weight: 400;
  }
`;

export const ModalDescription = styled.div`
  width: 200px;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const ButtonAction = styled.div`
  width: 100%;
  max-width: 410px;
  height: 75px;
  max-width: 410px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const MenuItemTableNumber = styled.p`
  width: 35px;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
`;

export const MenuItemBuyer = styled.p`
  width: 45px;
  font-size: 14px;

  span {
    font-size: 12px;
  }
`;

export const MenuItemCheck = styled.p`
  width: 35px;

  svg {
    margin-left: 12px;
  }
`;

export const MenuItemOrderTime = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
`;

export const ItemComplements = styled.div`
  margin-top: 8px;
`;

export const ItemComplementsQuestion = styled.div`
  font-weight: bold;
`;

export const ItemComplementsName = styled.div`
  display: flex;

  p {
    margin-left: 8px;
  }
`;

export const ItemOrderId = styled.div`
  color: #fb4a20;
  font-family: Arial, Helvetica, sans-serif;
`;

export const ItemBuyer = styled.div`
  margin-top: 8px;
  font-family: Arial, Helvetica, sans-serif;
`;
