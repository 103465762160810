import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';

import api from '../../services/api';

import { useAuth } from '../auth';
import Modal from 'react-modal'
import { ConfirmButton, ModalFooter, ModalTitle } from '../../pages/Menu/styles';

const WaiterTicketContext = createContext();



const WaiterTicketProvider = ({ children }) => {
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
      border: 'none'
    },
  };
  const { websocketManager, user } = useAuth();

  const [, setLoading] = useState(false);
  const [isModalNewWaiterTicketOpened, setIsModalNewWaiterTicketOpened] = useState()
  const [waiterTickets, setWaiterTickets] = useState(() => {
    const waiterTicketsStorage = localStorage.getItem(
      '@waitermanager:waiterTickets'
    );

    if (waiterTicketsStorage) {
      return JSON.parse(waiterTicketsStorage);
    }

    return [];
  }, []);

  function toggleModalWaiterTicket(){
    setIsModalNewWaiterTicketOpened(!isModalNewWaiterTicketOpened)
  }


  const getTickets = useCallback(async () => {
    const response = await api.get('/waiter/help-requests');

    // const filtered = response.data.filter((ticket) => !ticket.checked);

    setWaiterTickets(response.data);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:waiterTickets',
      JSON.stringify(waiterTickets)
    );
  }, [waiterTickets]);

  useEffect(() => {
    getTickets();
  }, [getTickets]);

  /*
  useEffect(() => {
    let interval;
    if (user) {
      interval = setInterval(getAllClosingBills, 30000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [getAllClosingBills, user]);
  */

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(1, (data) => {
        console.log('WaiterTicketsContext: socket new data', data);
        if (data.type === 'update-help-request') {
          // getTickets();

          setWaiterTickets((state) => {
            const waiterTicket = data.item;
            const ticketFound = state.find(item => item.id === waiterTicket.id)

            if (ticketFound) {
              ticketFound.checked = true;
            }

            return [...state];
          });
        } else if (data.type === 'new-help-request') {
          const waiterTicket = data.item;
          setWaiterTickets((state) => [waiterTicket, ...state]);
          setIsModalNewWaiterTicketOpened(true)
        }
      });
    }
  }, [
    websocketManager,
    getTickets,
    waiterTickets,
    setWaiterTickets,
  ]);

  const value = useMemo(() =>
    ({ waiterTickets, isModalNewWaiterTicketOpened, setIsModalNewWaiterTicketOpened }),
    [waiterTickets, isModalNewWaiterTicketOpened, setIsModalNewWaiterTicketOpened]);

  return (
    <WaiterTicketContext.Provider value={value}>
      <Modal isOpen={isModalNewWaiterTicketOpened} onRequestClose={toggleModalWaiterTicket}
          style={customStyles}
          contentLabel="Modal de Chamado de Cliente">
        <ModalTitle style={{fontWeight: 'bold', marginBottom: 10}}>
          Nova Solicitação De Ajuda!
        </ModalTitle>
        <p>Mesa {waiterTickets[0]?.table_number} </p>
        <p>{waiterTickets[0]?.buyer?.phone} {` - ${waiterTickets[0]?.buyer?.name}`}</p>
        <p></p>
        <p>{waiterTickets[0]?.comment}</p>

        <ModalFooter>
        <ConfirmButton onClick={toggleModalWaiterTicket} style={{width: '100%', display: 'flex', justifyContent: 'center', fontWeight:'bold'}}>Ok, ciente!</ConfirmButton>
      </ModalFooter>
      </Modal>

      {children}
    </WaiterTicketContext.Provider>
  );
};

function useWaiterTickets() {
  const context = useContext(WaiterTicketContext);

  if (!context) {
    throw new Error('useWaiterTickets must be used within an ClosingProvider');
  }

  return context;
}

export { WaiterTicketProvider, useWaiterTickets };
