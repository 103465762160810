import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Input = styled.input`
  width: 100%;
  background-color: #fff;
  border: 1px solid #CDCDCD;
  border-radius: 10px;
  font-family: 'Poppins';
  font-size: 12px;
  padding: 10px 20px;
`;

export const TextMedium = styled.p`
  color: #3D3D3D;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Button = styled.button`
  align-items: center;
  font-family: 'Poppins';
  border-radius: 100px;
  height: 40px;
  justify-content: center;
  padding: 5px 20px;
`;

export const CancelButton = styled(Button)`
  background-color: transparent;
  border: 1px solid #FF2C3A;
  p {
    color: #FF2C3A;
  }
`;

export const ConfirmButton = styled(Button)`
  background-color: ${({ disabled }) => disabled ? '#f5f5f5' : '#2EC9B7' };
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer' };
  border: none;
`;

export const ConfirmText = styled.p`
  color: #fff;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
`;