import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 360px;
  max-width: 1000px;
  height: 100vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 55px;

  a {
    text-decoration: none;
    color: #0c78f3;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 700;
  }
`;

export const ModalContent = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    color: #0c78f3;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 700;
  }

  button {
    width: 100%;
    height: 48px;
    // background: linear-gradient(
    //   144deg,
    //   rgba(251, 74, 32, 1) 28%,
    //   rgba(250, 5, 97, 1) 100%
    // );
    background: #ff3c3a;
    color: #fff;
    font-weight: 500;
    border-radius: 24px;
    border: none;
    margin-top: 15px;
    
    span {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      margin-left: 8px;
      margin-top: 3px;
    }
  }
`;


export const Footer = styled.footer`
  width: 340px;
  height: 130px;
  background: #fff;
  position: fixed;
  bottom: 0;

  > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  button {
    width: 100%;
    height: 48px;
    background: #ff3c3a;
    color: #fff;
    margin-bottom: 10px;
    font-weight: 500;
    border-radius: 24px;
    border: none;
    margin-left: 5px;

    span {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      margin-left: 8px;
      margin-top: 3px;
    }
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-top: 10px;
  }

  p {
    margin-top: 10px;
    text-align: center;

    span {
      margin-top: 20px;
    }
  }
`;
