import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { FiTrash2, FiInfo } from 'react-icons/fi';
import {FaMinusCircle, FaPlusCircle, FaPen, FaTrash} from 'react-icons/fa'
import {
  Container,
  MenuItemBody,
  MenuItemImage,
  MenuItemDescription,
  MenuItemComplements,
  MenuItemQuantity,
  ModalTitle,
  ModalFooter,
  ConfirmButton,
  CancelButton,
} from './styles';

import { useCart } from '../../../context/cart';
import { Form } from '@unform/web';
import TextArea from '../../../components/TextArea';

const MenuItemList = ({ item }) => {
  const { removeFromCart, increment, decrement, addObservation } = useCart();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalObservation, setIsModalObservation] = useState(false);

  const formRef = useRef(null);


  function toggleModalOservation() {
    setIsModalObservation(!modalObservation);
  }

  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',

    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleRemoveFromCart(id) {
    removeFromCart(id);

    closeModal();
  }

  function replaceValue(value) {
    const newValue = value.replace('.', ',');

    return newValue;
  }

  async function handleSubmit(data) {
    try {
      await addObservation(
        item?.id,
        item?.ordering_system_id,
        data.observation
      );
      setIsModalObservation(false);
    } catch (error) {

      console.log(error);
    }
  }

  return (
    <Container>
            <Modal isOpen={modalObservation} toggle={toggleModalOservation}  style={customStyles}>
          {/* <ModalTitle>
            <p>Adicione a observação para o produto</p>
            </ModalTitle> */}
            <Form onSubmit={handleSubmit} ref={formRef}>
              <TextArea
                name="observation"
                type="text"
                placeholder="Insira a observação"
                id="observations_input"
              />
              <ModalFooter>
                <ConfirmButton type="submit">Enviar</ConfirmButton>
                <CancelButton onClick={toggleModalOservation}>
                  Cancelar
                </CancelButton>
              </ModalFooter>
            </Form>

        </Modal>

      <MenuItemBody key={item.basket_id}>
        {item.image && (
          <MenuItemImage>
            <img src={item.image.url_thumb || item.image.url} alt={item.name} style={{ width: 64 }} />
          </MenuItemImage>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalTitle>
            <p>Deseja realmente excluir esse produto?</p>
          </ModalTitle>
          <ModalFooter>
            <ConfirmButton
              onClick={() => {
                handleRemoveFromCart(item.basket_id);
              }}
            >
              Confirmar
            </ConfirmButton>
            <CancelButton onClick={closeModal}>Cancelar</CancelButton>
          </ModalFooter>
        </Modal>
        <MenuItemDescription>


          <strong>{item.name}</strong>
          {item.observation && (
            <div>
              <FiInfo color="#ff3c3a" />

              <span>{item.observation}</span>
            </div>
          )}

          <MenuItemComplements>
            {item.complement_categories.map(category =>
              category.complements
                .filter(comp => comp.amount > 0)
                .map(items => <p key={items.basket_id}>{items.name}</p>),
            )}
          </MenuItemComplements>
        </MenuItemDescription>
        <MenuItemQuantity>
        <FaPen onClick={toggleModalOservation}/>
          {item.use_weight ? (
            <strong> {`${replaceValue(item.weight)} kg`} </strong>
          ) : (
            <>
            {!item?.is_unitary && (
               <FaMinusCircle
               color="#ff3c3a"
               size={18}
               onClick={() => {
                 decrement(null, item.ordering_system_id);
               }}
             />
            )}


              <strong> {`${item.amount}`} </strong>

              {!item?.is_unitary && (
              <FaPlusCircle
                color="#ff3c3a"
                size={18}
                onClick={() => {
                  increment(null, item.ordering_system_id);
                }}
              />
              )}
            </>
          )}

          <FaTrash color="#ff3c3a" size={18} onClick={openModal} />
        </MenuItemQuantity>
      </MenuItemBody>
    </Container>
  );
};

export default MenuItemList;
