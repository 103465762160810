import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #4ceb5b;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #fb4a20;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const PromotionArea = styled.div`
  position: fixed ;
  bottom: 71px;
  width: 95%;
/* background-color: #f00000 ; */
  border-radius: 8px ;

  margin-left: 10px ;
  img {
    border-radius: 8px ;
    width: 100% ;
    max-width: 410px ;
  }

`

export const HeaderAction = styled.header`
  width: 100%;
  max-width: 410px;
  position: fixed;
  top: 0;
  padding: 20px;
  padding-top: 25px;
  z-index: 10;
  height: 30px ;
background-color: #fff ;
  display: flex;
  justify-content: space-between;
  align-items: center;


  h1 {
    font-size: 24px;
    font-weight: bold;
  }

  div {
    display: flex;
    font-size: 14px;

    p {
      margin-left: 4px;
      font-weight: bold;
      color: #fb4a20;
    }
  }
`;

export const SearchBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 95%;
  margin-top: -20px ;
  padding-left: 20px ;
  position: relative;
  button {
    background: #33333320;
    border: none;
    margin-left: 10px;
    height: 42px;
    width: 10%;
    border-radius: 8px;
  }

  input {
    width: 100%;
    height: 42px;
    border-radius: 8px;
    border: 1px solid #ff2b3a;
    z-index: 1;
    color:#ff2b3a90;
    padding-left: 38px;


    ::placeholder{
      color:#ff2b3a90;
    }
  }

  svg {
    color:#ff2b3a90;
    position: absolute;
    left: 30px ;
    z-index: 2 ;
  }
`;


export const Content = styled.div`
  width: 100%;
  max-width: 414px;

  display: flex;
  flex-direction: column;
  padding-bottom: 85px;
  margin-top: 65px;
`;

export const Footer = styled.footer`
  width: 100%;
  max-width: 410px;
  height: 75px;
  background: #fff;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #33333310;
`;

export const ButtonGoToAccount = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  color: #333;
  svg {
    margin-bottom: 4px;
  }
`;

export const MenuContent = styled.div`
  background: #fff;
  border-radius: 7px;
  margin-top: 48px;
`;

export const MenuItem = styled.li`
  list-style: none;
  padding: 5px;
`;

export const Badge = styled.div`
  font-size: 10px;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  width: 75px;
  height: 25px;
  color: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  text-align: center;
  font-weight: bold;
`;

export const AddToCart = styled(Link)`
  width: 100%;
  background: transparent;
  text-decoration: none;
  list-style: none;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;

    img {
      width: 52px;
      height: 52px;
      border-radius: 7px;
      margin-right: 5px;
    }
  }
`;

export const ButtonOption = styled(Link)`
  width: 74px;
  text-decoration: none;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #333;

  position: relative;
  font-size: 12px;

  cursor: pointer;

  svg {
    margin-bottom: 4px;

    ${item =>
    item.path === '/restauranttables' &&
    css`
        stroke: #fff;
      `}
  }

  ${item =>
    item.path === '/restauranttables' &&
    css`
      background: linear-gradient(
        144deg,
        rgba(251, 74, 32, 1) 28%,
        rgba(250, 5, 97, 1) 100%
      );
      border-radius: 7px;
      color: #fff;
    `}
`;

export const CountBadge = styled.div`
  position: absolute;
  top: 0;
  left: 45px;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  background: #fff;
  width: 22px;
  height: 22px;
  color: rgba(251, 74, 32, 1);
  border: 1px solid rgba(251, 74, 32, 1);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fb4a2010;
  border-radius: 7px;
  padding: 5px;
  div {
    font-size: 12px;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
      display: flex;
      align-items: center;
      > svg {
        margin-right: 5px;
      }
    }
    svg {
      margin-left: 5px;
    }
  }
  strong {
    font-weight: 700;
  }
`;

export const MenuItemBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
  & + div {
    margin-top: 40px;
  }
`;

export const MenuItemDescription = styled.div`
  width: 190px;
  margin-left: 8px;
  color: #333;
  strong {
    font-weight: 900;
  }
  p {
    font-size: 14px;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const MenuItemPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #333;
  p {
    font-size: 14px;

    font-family: Arial, Helvetica, sans-serif;
  }
`;

export const ButtonAction = styled.div`
  width: 100%;
  max-width: 410px;
  height: 75px;
  max-width: 410px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const Tables = styled.ul`
  width: 100%;
  max-width: 414px;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 96px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 14px;

`;
