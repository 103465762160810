import styled from 'styled-components';
// import NumberFormat from 'react-number-format';

// export const InputContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-top: 10px;
//   margin-bottom: 10px;
//   position: relative;

//   label {
//     font-weight: bold;
//   }

//   input {
//     height: 44px;
//     padding: 10px;
//     border-radius: 8px;
//     border: 1px solid lightgray;

//     &:focus {
//       border: 1px solid lightgray;
//       border-radius: 8px;
//     }
//   }
//   span {
//     font-weight: bold;
//     color: #333;
//   }
// `;

// export const RadioContainer = styled.div`
//   display: flex;
//   align-items: center;
//   input {
//     margin: auto 0;
//   }
//   label {
//     margin-left: 15px;
//     font-weight: bold;
//     padding-top: 8px;
//   }
// `;

export const TextareaContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;

  label {
    font-weight: bold;
  }

  textarea {
    height: 120px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid lightgray;
    resize: none;

    &:focus {
      border: 1px solid lightgray};
      border-radius: 8px;
    }

  span {
    font-weight: bold;
    color: #333;
  }
`

// export const SelectContainer = styled.div`
//   margin-top: 10px;
//   margin-bottom: 10px;
//   label {
//     font-weight: bold;
//   }
// `;
// export const CheckboxContainer = styled.div`
//   height: 100%;
//   display: flex;
//   align-items: center;
//   input {
//     margin: auto 0;
//   }
//   label {
//     margin-left: 20px;
//     font-weight: bold;
//   }
// `;

// export const InputPaymentContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   height: 80%;

//   label {
//     font-weight: bold;
//   }

//   input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }

//   input {
//     width: 100px;
//     padding: 5px;
//     font-size: 15px;
//     border-radius: 8px;
//     border-color: green;
//     border-effect: none;
//     border: 2px solid green;

//     &:focus {
//       border: 2px solid green;
//       border-radius: 8px;
//     }
//   }
//   span {
//     font-weight: bold;
//     color: ${(props) => props.theme.colors.secondary};
//   }
// `;

export const Error = styled.div`
  position: absolute;
  top: -25px;
  right: 0px;
  padding: 8px;
  border-radius: 7px;
  background: #fb4a20;

  &::before {
    content: '';
    border-style: solid;
    border-color: #fb4a20 transparent;
    border-width: 6px 6px 0 6px;
    top: 100%;
    bottom: 20px;
    position: absolute;
    right: 10px;
  }

  p {
    color: #fff;
    font-size: 10px;
    font-weight: bold;
  }
`;

// export const NumberFormatStyled = styled(NumberFormat)`
//   height: 44px;
//   padding: 10px;
//   border-radius: 8px;
//   border: 1px solid ${(props) => props.theme.colors.lightGray};

//   &:focus {
//     border: 1px solid ${(props) => props.theme.colors.gray};
//     border-radius: 8px;
//   }
// `;
