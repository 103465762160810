import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';

import api from '../../services/api';

import { useAuth } from '../auth';

const OrdersContext = createContext();

const OrdersProvider = ({ children }) => {

  const { websocketManager, userToken } = useAuth();


  const [ordersPending, setOrdersPending] = useState(() => {
    const ordersPendingStorage = localStorage.getItem(
      '@waitermanager:ordersPending'
    );

    if (ordersPendingStorage) {
      return JSON.parse(ordersPendingStorage);
    }

    return [];
  });

  const [ordersAccepted, setOrdersAccepted] = useState(() => {
    const ordersAcceptedStorage = localStorage.getItem(
      '@waitermanager:ordersAccepted'
    );

    if (ordersAcceptedStorage) {
      return JSON.parse(ordersAcceptedStorage);
    }

    return [];
  });

  const getOrders = useCallback(async () => {
    const response = await api.get('/waiter/orders');

    const ordersPending = response.data
      .filter((order) => !order.waiter_checked)
      .sort((a, b) => Number(b.id) - Number(a.id));

    setOrdersPending(ordersPending);

    const ordersAccepted = response.data
      .filter((order) => order.waiter_checked)
      .sort((a, b) => Number(b.id) - Number(a.id));

    setOrdersAccepted(ordersAccepted);
  }, []);

  async function checkItem(id) {
    await api.put('/waiter/orders-check', {
      order_id: id,
      waiter_checked: true,
    });

    const orderFind = ordersPending.find(
      (item) => Number(item.id) === Number(id)
    );

    if (orderFind) {
      setOrdersAccepted((item) => {
        return [orderFind, ...item];
      });
    } else {
      console.log('erro', ordersPending, orderFind);
    }

    setOrdersPending((item) => {
      const orderFiltered = item.filter(
        (item) => Number(item.id)!== Number(id)
      );

      return orderFiltered;
    });

  }

  async function uncheckItem(id) {
    await api.put('/waiter/orders-check', {
      order_id: id,
      waiter_checked: false,
    });
    const orderAcceptedFind = ordersAccepted.find(
      (item) => Number(item.id) === Number(id)
    );

    if (orderAcceptedFind) {
      setOrdersPending((item) =>{
        return [orderAcceptedFind, ...item];
      });
    } else {
      console.log('err', orderAcceptedFind, orderAcceptedFind);
    }

    setOrdersAccepted((item) =>{
      const orderFiltered = item.filter(
        (item) => Number(item.id)!== Number(id)
      );

      return orderFiltered;
    });
  }

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:ordersPending',
      JSON.stringify(ordersPending)
    );
  }, [ordersPending]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:ordersAccepted',
      JSON.stringify(ordersAccepted)
    );
  }, [ordersAccepted]);

  useEffect(() => {
    if(userToken){
      getOrders();
    }

  }, [getOrders, userToken]);

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(2, (data) => {
        console.log('OrdersContext: socket new data', data);
        if (data.type === 'order-check') {
          const order = data.item;

          if (order.waiter_checked) {
            const orderFind = ordersPending.find(
              (item) => Number(item.id) === Number(order.order_id)
            );

            if (orderFind) {
              setOrdersAccepted((item) => {
                return [orderFind, ...item];
              });
            } else {
              console.log('erro', ordersPending, orderFind);
            }

            setOrdersPending((item) => {
              const orderFiltered = item.filter(
                (item) => Number(item.id)!== Number(order.order_id)
              );

              return orderFiltered;
            });

          } else {
            const orderAcceptedFind = ordersAccepted.find(
              (item) => Number(item.id) === Number(order.order_id)
            );

            if (orderAcceptedFind) {
              setOrdersPending((item) =>{
                return [orderAcceptedFind, ...item];
              });
            } else {
              console.log('err', orderAcceptedFind, orderAcceptedFind);
            }

            setOrdersAccepted((item) =>{
              const orderFiltered = item.filter(
                (item) => Number(item.id)!== Number(order.order_id)
              );

              return orderFiltered;
            });
         }
        } else if (data.type === 'basket') {
          const order = data.item;

          setOrdersPending((item) => {
            return [...order, ...item];
          });
        } else if(data.type === "basket-changed"){
          const order = data.item;



          if(order.order_status === 'finished'){
            const id = order.id

            const orderFindAtPending = ordersPending.find(item=>Number(item.basket.id) === Number(id))

            if(orderFindAtPending){

              setOrdersPending((item) => {
                const orderFiltered = item.filter(
                  (item) => Number(item.basket.id)!== Number(id)
                );

                return orderFiltered;
              });

            }

            const orderFindAtAccepted = ordersAccepted.find(item=>Number(item.basket.id) === Number(id))

            if(orderFindAtAccepted){

            setOrdersAccepted((item) => {
              const orderFiltered = item.filter(
                (item) => Number(item.basket.id)!== Number(id)
              );

              return orderFiltered;
            });

            }


          }
        }
      });
    }
  }, [
    websocketManager,
    setOrdersAccepted,
    setOrdersPending,
    ordersAccepted,
    ordersPending
  ]);

  const value = useMemo(() =>
    ({ ordersAccepted, ordersPending, getOrders, checkItem, uncheckItem }),
    [ordersAccepted, ordersPending,getOrders, checkItem, uncheckItem]);

  return (
    <OrdersContext.Provider value={value}>
      {children}
    </OrdersContext.Provider>
  );
};

function useOrders() {
  const context = useContext(OrdersContext);

  if (!context) {
    throw new Error('useOrders must be used within an OrdersProvider');
  }

  return context;
}

export { OrdersProvider, useOrders };
