import React from 'react';
import { FiEye, FiSmartphone, FiCheckSquare, FiXCircle } from 'react-icons/fi';
import { Container, Content, ItemList, Table, Client, Value, Paid, Action } from './styles';
import formatCompleteDate from '../../../services/formatCompleteDate'
import { useCallback } from 'react';
import api from '../../../services/api';

function PaymentsPixItem({pix, pixes, setPixes, getPixCount}) {

  const checkPik = useCallback(async()=>{
    const response = await api.put(`waiter/pix/${pix.id}`)

    const pixFound = pixes.findIndex(item => item.id === pix.id)

    pixes[pixFound] = {
      ...pix,
      checked: true
    }

    const newPixes = [...pixes]

    setPixes(newPixes)

    getPixCount()


  }, [pix, setPixes])


  return (
    <Container>
      <Content>
        <ItemList>
          <Table>{pix.table_session.table.table_number}</Table>
          <Client>{pix.phone}</Client>
          <Value>{`R$ ${pix.total}`}</Value>
          <Paid>{formatCompleteDate(pix.payed_at)}</Paid>
          <Action><FiCheckSquare size={16} color='green' onClick={checkPik}/></Action>
        </ItemList>
      </Content>

    </Container>
  )
}

export default PaymentsPixItem;
