import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { uuid } from 'uuidv4';
import NotificationBadge from 'react-notification-badge';

import { Effect } from 'react-notification-badge';
import Modal from 'react-modal';
import Shape from '../../assets/images/shape.png'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import noImage from '../../assets/images/noImage.png'

import ProductDetails from './ProductDetails'
import {
  FiCoffee,
  FiArrowLeft,
  FiCheckCircle,
  FiX,
  FiSearch,
} from 'react-icons/fi';

import {
  MdRestaurant
} from 'react-icons/md';

import { FaClock, FaEye, FaUser, FaUserFriends, FaUsers } from 'react-icons/fa';

import { AiOutlineReload } from 'react-icons/ai';

import { useCart } from '../../context/cart';
import { useAuth } from '../../context/auth';
import { useMenu } from '../../context/menu';

import formatValue from '../../utils/formatValue';
import { Modal as ModalStrap } from 'reactstrap';

import logo_icon from '../../assets/images/logo_icon.png';

import {
  Container,
  Content,
  MenuContent,

  MenuItemHeader,
  MenuItemBody,
  HeaderAction,
  AddToCart,
  Footer,
  ButtonGoToCart,
  ButtonGoToAccount,
  ButtonAction,
  SearchBar,
  ProductPrice,
  ProductName,
  ProductImage,
  ProductInfo,
  ProductsArea,
  ButtonDetails,
  ButtonOutline
} from './styles';
import Product from '../Product';

const Menu = props => {
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      height: '500px',
      maxWidth: '390px',
      padding: '0px',
      top: '60%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -60%)',
      borderRadius: '7px',
      zIndex: '1000',
    },
  };

  const { cart, addToCart } = useCart();
  const [modalProductIsOpen, setModalProductIsOpen] = useState(false);
  const [modalFullProductIsOpen, setModalFullProductIsOpen] = useState(false);
  const { menu, handleSearchInputProduct, productsFiltered, setProductsFiltered } = useMenu()
  const [itemProduct, setItemProduct] = useState();
  const {
    restaurantFantasyName,
    tableNumber,
    tableStatus,
    isBalcony,
    isPos,
    restaurantUseCommand,
    stoneDevice,
    getPos
  } = useAuth();

  const history = useHistory();
  const inputRef = useRef(null);

  const cartAmount = cart.reduce((accum, curr) => accum + curr.amount, 0)

  // function getMobileOperatingSystem() {
  //   var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  //   if( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i ) )
  //   {
  //     setSystemOp('ios');

  //   }
  //   else if( userAgent.match( /Android/i ) )
  //   {

  //     setSystemOp('android');
  //   }
  //   else
  //   {
  //     setSystemOp('unknown');
  //   }
  // }

  // useEffect(()=>{
  //   getMobileOperatingSystem()
  // }, [])

  function minimumComplementValue(complements) {
    const complementHasPrice = complements
      ? complements.map(comp => comp.complements.filter(cp => cp.price > 0))
      : 0;

    if (complementHasPrice.length > 0) {
      complementHasPrice[0].sort((a, b) => {
        if (parseFloat(a.price) < parseFloat(b.price)) {
          return -1;
        }

        if (parseFloat(a.price) === parseFloat(b.price)) {
          return 0;
        }

        return 1;
      });
    }

    const price = complementHasPrice.length > 0 && complementHasPrice[0][0]?.price
      ? complementHasPrice[0][0].price
      : '';

    return formatValue(price);
  }

  function handleGoToAccount() {
    if (tableStatus === 'open') {
      history.push('/closeaccount');
    } else {
      alert('Mesa sem conta');
    }
  }

  // function find(e){
  //   e.preventDefault();

  //   window.find(inputRef.current.value);
  // }


  // function finder(e){
  //   document.execCommand(find(e))
  // }

  function handleGoToCart() {
    if (isPos.toString() === 'true' && stoneDevice === null) {
      alert('Não existe POS Stone vinculada a esse garçom.');
    } else {
      if (cart.length < 1) {
        alert('Seu carrinho está vazio =/');
      } else {
        history.push('/cart');
      }
    }
  }

  function getCategoryImage(cat) {
    const image = cat.products.filter(prd => prd.image !== null)
    if (cat.image) {
      return cat.image.url_thumb || cat.image.url
    } else if (image) {
      return image[0]?.image.url_thumb || image[0]?.image.url
    } else {
      return Shape
    }
  }

  useEffect(() => {
    setProductsFiltered()
  }, [setProductsFiltered])

  function openProductModal(product) {
    setModalProductIsOpen(true);

    setItemProduct({
      ...product,
      price: product.price_promotion || product.price,

    });
  }

  const [productState, setProductState] = useState({})

  function openFullProductModal(product) {
    setModalFullProductIsOpen(true);

    setProductState({
      ...product,
      price: product.price_promotion || product.price,

    });
  }

  function toggleModalProduct() {
    setModalProductIsOpen(!modalProductIsOpen);
  }

  function closeProductModal() {
    setModalProductIsOpen(false);
  }

  function toggleModalFullProduct() {
    setModalProductIsOpen(!modalFullProductIsOpen);
  }

  function closeFullProductModal() {
    setModalFullProductIsOpen(false);
  }

  function handleSelectProduct(product) {
    if (product?.complement_categories.length > 0 || product?.use_weight) {
      openFullProductModal(product);
      // history.push({
      //   pathname: '/product',
      //   state: {
      //     product: {
      //       ...product,
      //       price: product?.price_promotion || product?.price,
      //     },
      //   }
      // })
    } else {
      const productFormatted = {
        ...product,
        price: product.price_promotion || product.price,
      };

      addToCart({
        product: productFormatted,
        amount: 1,
        observation: '',
        complementsCategory: [],
        isProductWeight: product?.use_weight,
      });

      countProducts({ id: product.id, amount: 1 })
    }
  }

  const [productsHasCounted, setProductsHasCounted] = useState([])

  function countProducts(product) {
    const productFind = productsHasCounted.findIndex(item => item.id === product.id)

    if (productFind !== -1) {
      productsHasCounted[productFind].amount += 1
    } else {
      setProductsHasCounted(state => {
        return [...state, product]
      })
    }
  }

  function handleScroll(id) {
    const element = document.getElementById(`${id}`);
    const elementPosition = element.offsetTop - 150; // Obtém a posição do elemento em relação ao topo da página

    window.scrollTo({
      top: elementPosition,
      behavior: "smooth" // Rolagem suave
    });
  }

  return (
    <Container>
      <Content>

        <ModalStrap
          style={{
            height: '100%',
            background: '#fff',
            borderRadius: '0',
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1060,
            width: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            outline: 0
          }}
          isOpen={modalFullProductIsOpen}
          toggle={toggleModalFullProduct}

        >
          <Product
            product={productState}
            closeFullProductModal={closeFullProductModal}
            countProducts={countProducts}
          />
        </ModalStrap>

        <Modal isOpen={modalProductIsOpen} toggle={toggleModalProduct} style={customStyles} onClosed={toggleModalProduct}>
          <ProductDetails
            product={itemProduct}
            closeProductModal={closeProductModal}
          />
        </Modal>

        <HeaderAction>
          <header>
            <h1>{restaurantFantasyName}</h1>
            <div>
              <FaUserFriends size={20} /> <span>{isBalcony.toString() === 'true' ? 'Balcão' : (isPos.toString() === 'true' ? 'POS' : (restaurantUseCommand.toString() === 'true' ? `Comanda ${tableNumber}` : `Mesa ${tableNumber}`))}</span>
            </div>
          </header>
          {/* <MenuScrollNav menu={menu} handleExpandCategory={handleExpandCategory}/> */}


          <SearchBar>
            <input
              name="product"
              type="text"
              placeholder="Buscar produto"
              onChange={e => handleSearchInputProduct(e.target.value)}
              ref={inputRef}
            />
            <FiSearch size={18} color='#666666' />
            <FiX style={{ left: '90%' }} onClick={() => {
              setProductsFiltered([]);
              inputRef.current.value = ''
            }} color='#D9D9D9' size={22} />
          </SearchBar>
        </HeaderAction>


        {productsFiltered && productsFiltered.length > 0 ? (
          <MenuContent >
            <MenuItemBody>
              {productsFiltered.filter(comp => comp.available).map(item => (

                <ProductsArea>
                  <NotificationBadge count={productsHasCounted ? productsHasCounted.find(pr => pr.id === item.id)?.amount : 0} effect={Effect.SCALE} style={{ width: '25px', height: '25px', borderRadius: '12px', position: 'absolute', top: '0px', left: '110px', paddingTop: '5px' }} />
                  <ButtonDetails type='button' onClick={() => { openProductModal(item) }}><FaEye color="#5DCCB1" /></ButtonDetails>
                  <AddToCart
                    disable={item?.sold_off.toString()}
                    onClick={() => { handleSelectProduct(item); }}

                  >
                    <ProductImage>
                      <img src={item?.image?.url_thumb || item?.image?.url || noImage} alt={item?.name} />
                    </ProductImage>

                    <ProductInfo>
                      <ProductName>{item?.name}</ProductName>

                      {item?.price_promotion ? (
                        <>
                          <ProductPrice>{formatValue(item?.price_promotion)}</ProductPrice>
                        </>
                      ) : (
                        <>
                          {item?.price > 0 ? (<ProductPrice>{formatValue(item?.price)}</ProductPrice>) : (<ProductPrice>{minimumComplementValue(item?.complement_categories)}</ProductPrice>)}

                        </>
                      )}
                    </ProductInfo>

                  </AddToCart>
                </ProductsArea>

              ))}
            </MenuItemBody>
          </MenuContent>
        ) : (
          <MenuContent>

            {menu && menu.filter(cat => cat.available).map(category => (
              <React.Fragment key={category?.id}>
                {category?.products.length >= 1 && (
                  <Accordion allowZeroExpanded >
                    <AccordionItem id={category?.id} onClick={() => handleScroll(category?.id)}>
                      <AccordionItemButton className={category?.name}  >
                        <MenuItemHeader name={category.name} style={{
                          backgroundImage: `linear-gradient(to right, rgba(0,0,0,.8), rgba(255,255,255,0)), url(${getCategoryImage(category) || Shape
                            })`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'

                        }}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>

                            <strong>{category.name}</strong>
                            {category.preparation_time > 0 && (
                              <span style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                <FaClock />
                                <span style={{ fontSize: '12px' }}>{`${category.preparation_time} min.`} </span>

                              </span>
                            )}
                          </div>

                        </MenuItemHeader>
                      </AccordionItemButton>

                      <MenuItemBody >
                        {category?.products?.filter(comp => comp.available).map(item => (
                          <AccordionItemPanel key={uuid()}  >
                            <ProductsArea>

                              <ButtonDetails type='button' onClick={() => { openProductModal(item) }}><FaEye color="#5DCCB1" /></ButtonDetails>

                              <NotificationBadge count={productsHasCounted ? productsHasCounted.find(pr => pr.id === item.id)?.amount : 0} effect={Effect.SCALE} style={{ width: '25px', height: '25px', borderRadius: '12px', position: 'absolute', top: '-10px', left: '97px', paddingTop: '5px' }} />
                              <AddToCart
                                disable={item?.sold_off.toString()}
                                onClick={() => { handleSelectProduct(item) }}
                              >

                                <ProductImage>
                                  <img src={item?.image?.url_thumb || item?.image?.url || noImage} alt={item?.name} />
                                </ProductImage>

                                <ProductInfo>
                                  <ProductName>{item?.name}</ProductName>
                                  {item?.price_promotion ? (
                                    <>

                                      <ProductPrice>{formatValue(item?.price_promotion)}</ProductPrice>
                                    </>
                                  ) : (
                                    <>
                                      {item?.price > 0 ? (<ProductPrice>{formatValue(item?.price)}</ProductPrice>) : (<ProductPrice>{minimumComplementValue(item?.complement_categories)}</ProductPrice>)}

                                    </>

                                  )}
                                </ProductInfo>
                              </AddToCart>

                            </ProductsArea>

                          </AccordionItemPanel>
                        ))}
                      </MenuItemBody>
                    </AccordionItem>
                  </Accordion>
                )}
              </React.Fragment>
            ))}
          </MenuContent>
        )}

        <Footer>
          <div>
            <ButtonOutline
              onClick={() => {
                history.push('/restauranttables');
              }}
            >
              <FiArrowLeft size={22} color="#FF2B3A" /> Início
            </ButtonOutline>
            <ButtonAction>
              <ButtonGoToCart onClick={handleGoToCart}>
                <img src={logo_icon} alt="logo da takeat" style={{ position: 'absolute', top: '10px' }} />
                {/* {!!cart.length > 0 && <CountBadge>{cartAmount}</CountBadge>} */}

                <NotificationBadge count={cartAmount} effect={Effect.SCALE} style={{ width: '25px', height: '25px', borderRadius: '12px', position: 'absolute', top: '10px', paddingTop: '5px' }} />
              </ButtonGoToCart>
              <span>Pedir</span>
            </ButtonAction>
            {
              isPos.toString() === 'true' ?
                <ButtonOutline onClick={getPos}>
                  <AiOutlineReload size={22} color="#FF2B3A" />
                  {stoneDevice ? stoneDevice.serial_number : 'Sem POS'}
                </ButtonOutline>
                :
                <ButtonOutline onClick={handleGoToAccount}>
                  <MdRestaurant size={17} color="#FF2B3A" />
                  Comanda
                </ButtonOutline>
            }
            {/* <ButtonGoToAccount onClick={handleGoToAccount}>
              <FiCheckCircle size={22} color="#fb4a20" />
              conta
            </ButtonGoToAccount> */}
          </div>
        </Footer>
      </Content>

    </Container >
  );
};

export default Menu;
