import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`

`


export const ItemList = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 7px;
  border-radius: 7px;
  font-size: 12px;
  margin-top: 12px;

  & + & {
    border-top: 1px solid #33333320;
  }


`

export const Table = styled.span`
  width: 50px ;
`
export const Client = styled.span`
width: 120px ;
`
export const Value = styled.span`
  width: 80px ;
  display: flex ;
 justify-content: center;

`
export const Paid = styled.span``
export const Action = styled.span`
 width: 100px ;

 display: flex ;
 justify-content: flex-end;
`
