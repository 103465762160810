import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../context/auth';
import * as Yup from 'yup'

import Input from '../../components/Input'

import { Container, Title, Content, Logo, StyledForm, InputArea, FiEyeStyled } from './styles';

import getValidationErrors from '../../services/getValidationErrors';

const Login = () => {
  const history = useHistory();
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  const { waiterLogin } = useAuth();

  const formRef = useRef(null)

  function togglePasswordType() {
    const password = document.querySelector('#password');

    const type =
      password.getAttribute('type') === 'password' ? 'text' : 'password';

    password.setAttribute('type', type);
  }

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        email: Yup.string().email('Email Inválido ou com espaço em branco').required('Usuário obrigatório').trim(),
        password: Yup.string().required('Senha obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });


      await waiterLogin(data);

      history.push('/orderspending');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        console.log(error);
        alert('Verifique seu email e senha, e tente novamente ...');
      }

    }
  }

  // function handleEmailInput(e) {
  //   setEmail(e.target.value);
  // }

  // function handlePasswordlInput(e) {
  //   setPassword(e.target.value);
  // }

  return (
    <Container>
      <Content>
        <Title>
          <Logo />
        </Title>

        <StyledForm onSubmit={handleSubmit} ref={formRef}>
          <p style={{color: '#fb4a20' }}>Faça seu login no espaço do garçom.</p>

          <Input
            type="text"
            placeholder="Email"
            name="email"
          />
          <InputArea>
          <Input
            type="password"
            placeholder="Senha"
            name="password"
            id="password"
          />
          <FiEyeStyled onClick={togglePasswordType}/>
          </InputArea>


          <button type="submit">Logar</button>
        </StyledForm>
      </Content>
    </Container>
  );
};

export default Login;
