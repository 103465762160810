import React, {createContext, useContext, useMemo, useEffect, useState} from 'react';
import api from '../../services/api'
import {useAuth} from '../auth'

const MenuContext = createContext()

const MenuProvider = ({children}) => {
const { restaurantId } = useAuth();
const [menuProducts, setMenuProducts] = useState([]);
const [productsFiltered, setProductsFiltered] = useState();

  const [menu, setMenu] = useState(()=>{
    const menuStorage = localStorage.getItem('@waitermanager:menu');

    if(menuStorage){
      return JSON.parse(menuStorage)
    }

    return []
  });


  async function getMenu(){

    const response = await api.get(
      `public/restaurants/menu/${restaurantId}`,
    );
    setMenu(response.data);

  }

  async function handleSearchInputProduct(data) {

    if (data.length >= 3) {
      try {
        // if (inputProductRef?.current) {
        //   inputProductRef.current.setErrors({});
        // }
        // const schema = Yup.object().shape({
        //   product: Yup.string().required('Produto obrigatório'),
        // });

        // await schema.validate(data, {
        //   abortEarly: false,
        // });

        // const product = data.product.toLowerCase();
        // const response = await api.get(`public/restaurants/products-available/${restaurantId}?name=${product}&delivery=false`);

        // const productFind = response.data.filter((prod) => {
        //   if (prod.name.toLowerCase().includes(product)) {
        //     return prod;
        //   }
        //   return '';
        // }

        const products = [];

        menu
          .filter((cat) => cat?.available === true)
          .map((cat) => cat.products.map((prod) => products.push(prod)));

        setMenuProducts(products);

        const productFind = menuProducts.filter((prod) => {
          if (
            prod.name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(data.toLowerCase())
          ) {
            return prod;
          }
          return '';
        });


        setProductsFiltered(productFind);
      } catch (error) {
        // if (error instanceof Yup.ValidationError) {
        //   const errors = getValidationErrors(error);
        //   if (inputProductRef?.current) {
        //     inputProductRef.current.setErrors(errors);
        //   }
        // } else {
          // console.tron.log(error);
          console.log(`Produto não encontrado!`);
        }

    }


      if (data.length === 0) {
        setProductsFiltered({ products: [] });
      }

  }

  useEffect(()=>{
    try{
      getMenu()
    }catch(e){
      console.log("erro ao carregar menu")
    }
  }, [restaurantId])

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        getMenu()
      } catch (err) {
        console.log(err);
      }
    }, 300000);
    return () => clearInterval(interval);
  }, [getMenu]);


  useEffect(() => {
    localStorage.setItem('@waitermanager:menu', JSON.stringify(menu));
  }, [menu]);


  const value = useMemo(()=>({
    menu,
    setMenu,
    handleSearchInputProduct,
    productsFiltered,
    setProductsFiltered

  }), [menu, setMenu, productsFiltered])

  return(
    <MenuContext.Provider value={value}>
      {children}

    </MenuContext.Provider>
  )

}

function useMenu(){
  const context = useContext(MenuContext);

  if(!context){
    console.log('useMenu must be within a provider');
  }

  return context;
}

export {MenuProvider, useMenu}
