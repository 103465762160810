import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useAuth } from '../../context/auth';
import { FiArrowLeft } from 'react-icons/fi';
import { FaMinus, FaPlus, FaUserFriends } from 'react-icons/fa';

import TableAccount from '../TableAccount';

import {
  Container,
  Content,
  HeaderAction,
  RouterDomLink,
  Nav,
  PeopleArea,
  NumberFormatStyled,
} from './styles';
import api from '../../services/api';
import { ActionButton } from 'ui-kit-takeat';

const CloseAccount = () => {
  const { tableNumber } = useAuth();

  function currencyFormatter(value) {
    if (value == 0) {
      return '';
    }

    if (Number(value) === 1) {
      return `${value} pessoa`;
    } else {
      return `${value} pessoas`;
    }
  }

  const [totalTable, setTotalTable] = useState([]);
  const [peopleAtTable, setPeopleAtTable] = useState(1);
  const [oldPeopleAtTable, setOldPeopleAtTable] = useState(null);
  const [inputChanged, setInputChanged] = useState(false);

  const { sessionKey } = useAuth();

  const updatePeopleAtTable = useCallback(
    async ({ people }) => {
      try {
        await api.put(`waiter/session/people`, {
          session_key: sessionKey,
          people,
        });
      } catch (err) {
        console.log(err);
        alert(err?.response?.data?.error);
      }
    },
    [sessionKey],
  );

  async function fetchData() {
    const res = await api.get('waiter/table-sessions', {
      params: { session_key: sessionKey },
    });

    const dataTable = res.data;

    setTotalTable([dataTable]);
    setPeopleAtTable(dataTable.people_at_table || 1);
  }
  useEffect(() => {
    fetchData();
  }, [sessionKey]);

  return (
    <Container>
      <Content>
        <HeaderAction>
          <header>
            <span
              style={{ display: 'flex', flexDirection: 'column', fontSize: 16 }}
            >
              <h1>Comanda</h1>

              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 16,
                  marginTop: 12,
                  color: '#FF2C3A',
                }}
              >
                <FaUserFriends
                  color="#FF2C3A"
                  size={21}
                  style={{ marginRight: 6 }}
                />{' '}
                Mesa {tableNumber}{' '}
              </span>
            </span>
            <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                justifyContent: 'space-between',
                fontSize: 16,
              }}
            >
              <RouterDomLink to={`/menu`}>
                <FiArrowLeft size={18} color="#FF2C3A" />
                <span style={{ color: '#FF2C3A' }}>Voltar</span>
              </RouterDomLink>

              <PeopleArea>
                <ActionButton
                  icon={<FaMinus />}
                  width={30}
                  height={30}
                  buttonColor={peopleAtTable <= 1 && '#f6f6f6'}
                  pointerEvents={peopleAtTable <= 1 && 'none'}
                  onClick={() => {
                    if (Number(peopleAtTable) > 1) {
                      setPeopleAtTable(Number(peopleAtTable) - 1);
                      updatePeopleAtTable({
                        people: Number(peopleAtTable) - 1,
                      });
                    }
                  }}
                />
                <NumberFormatStyled
                  format={currencyFormatter}
                  name="value"
                  placeholder="Nº pessoas"
                  value={Number(peopleAtTable)}
                  onValueChange={e => {
                    setPeopleAtTable(e.value);
                    if (e.value.length > 0) {
                      setInputChanged(true);
                    }
                  }}
                  onFocus={() => {
                    setInputChanged(false);
                    setOldPeopleAtTable(peopleAtTable);
                    setPeopleAtTable('');
                  }}
                  onBlur={() => {
                    if (inputChanged) {
                      updatePeopleAtTable({
                        people: Number(peopleAtTable),
                      });
                    } else {
                      setPeopleAtTable(oldPeopleAtTable);
                    }
                  }}
                />
                <ActionButton
                  icon={<FaPlus />}
                  width={30}
                  height={30}
                  buttonColor="#3BD2C1"
                  onClick={() => {
                    setPeopleAtTable(Number(peopleAtTable) + 1);
                    updatePeopleAtTable({
                      people: Number(peopleAtTable) + 1,
                    });
                  }}
                />
              </PeopleArea>
            </span>
          </header>
        </HeaderAction>

        <Tabs>
          <Nav>
            <TabList>
              <Tab></Tab>
            </TabList>
          </Nav>
          <TabPanel style={{ marginTop: 25 }}>
            <TableAccount totalTable={totalTable} fetchData={fetchData} />
          </TabPanel>
        </Tabs>
      </Content>
    </Container>
  );
};

export default CloseAccount;
