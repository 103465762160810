import React, { useState } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import { Container, CategoryList, CategoryThumbnailLink } from './styles';
import BuyerScrollItem from './BuyerScrollItem';
import { useAuth } from '../../context/auth';
import { useEffect } from 'react';
import { FiPlus } from 'react-icons/fi';
import { FaUser, FaUserPlus } from 'react-icons/fa';

function BuyersScrollNav({ buyers, createBuyer, newBuyer }) {

  const {setBuyerSelectedByWaiter, buyerCreatedByWaiter} = useAuth()
  const [selectedItem, setSelectedItem] = useState()

  function handleSelectBuyer(name, id){

    if(name[0]==='+'){
      if(buyerCreatedByWaiter){
        alert('Você já criou um usuário')
      }else {
        createBuyer(name)
      }

    }else {
      setBuyerSelectedByWaiter({
        name: name,
        id: id
      })
    }
  }


  function handleSelectItem(id) {
    if(id !== -33801){
      setSelectedItem(id);
    }

  }

  useEffect(()=>{newBuyer.toString() === 'true' ? setSelectedItem(-33802) : setSelectedItem(-33800) }, [newBuyer])

  return (
    <Container>{console.log(buyers)}


      <ScrollContainer className="scroll-container">
        {buyers && buyers.map(buyer => (
         <CategoryList onClick={()=>{handleSelectBuyer(buyer.name ? buyer.name : buyer.phone , buyer.id); handleSelectItem(buyer.id)}} key={buyer.id}>
            <CategoryThumbnailLink
                alan={selectedItem === buyer.id ? "selected" : ""} buttonAdd = {buyer?.name[0]==='+'}
              ><span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{buyer?.name[0]==='+' ? <FaUserPlus color='#3BD2C1'/> : buyer?.name ? buyer?.name[0].toUpperCase() : <FaUser />}</span>
            </CategoryThumbnailLink>
          <p>{buyer?.name || buyer?.phone.substring(11, 15 )}</p>
         </CategoryList>
        ))}
      </ScrollContainer>

    </Container>
  );
}

export default BuyersScrollNav;

