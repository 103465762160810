import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderAction = styled.div`
  position: fixed;
  top: 0;
  padding-top: 14px;
  padding-right: 10px;
  background: #fff;
  width: 100%;
  max-width: 410px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }
    > div {
      font-size: 14px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 414px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  padding-bottom: 85px;
`;

export const Footer = styled.footer`
  width: 100%;
  max-width: 410px;
  height: 75px;
  background: #fff;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #33333310;
`;

export const ButtonGoToAccount = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  color: #333;
  svg {
    margin-bottom: 4px;
  }
`;

export const MenuContent = styled.div`
  background: #fff;
  border-radius: 7px;
  margin-top: ${props => `calc(${props.cabecalhoHeight}px)`};
`;

export const MenuItem = styled.li`
  list-style: none;
  padding: 5px;
`;

export const Badge = styled.div`
  font-size: 10px;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  width: 75px;
  height: 25px;
  color: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  text-align: center;
  font-weight: bold;
`;

export const AddToCart = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const ButtonGoToCart = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  border-radius: 50%;
  margin-bottom: 25px;
  position: relative;
  cursor: pointer;
`;

export const CountBadge = styled.div`
  position: absolute;
  top: 0;
  left: 45px;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  background: #fff;
  width: 22px;
  height: 22px;
  color: rgba(251, 74, 32, 1);
  border: 1px solid rgba(251, 74, 32, 1);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  border-radius: 7px;
  padding: 5px;
  div {
    font-size: 12px;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
      display: flex;
      align-items: center;
      > svg {
        margin-right: 5px;
      }
    }
    svg {
      margin-left: 5px;
    }
  }
  strong {
    font-weight: 700;
  }
`;

export const MenuItemBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  & + div {
    padding-top: 20px;
    border-top: 1px solid #33333310;
  }
`;

export const MenuItemDescription = styled.div`
  width: 110px;
  margin-left: 8px;
  font-size: 14px;
  color: #333;
  strong {
    font-weight: 400;
  }
`;

export const ButtonAction = styled.div`
  width: 100%;
  max-width: 410px;
  height: 75px;
  max-width: 410px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const ButtonOption = styled(Link)`
  width: 74px;
  text-decoration: none;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #333;

  position: relative;
  font-size: 12px;

  cursor: pointer;

  svg {
    margin-bottom: 4px;

    ${item =>
    item.path === '/ordersclosed' && item.name === item.path &&
    css`
        stroke: #fff;
      `}
  }

  ${item =>
    item.path === '/ordersclosed' &&
    css`
      background: linear-gradient(
        144deg,
        rgba(251, 74, 32, 1) 28%,
        rgba(250, 5, 97, 1) 100%
      );
      border-radius: 7px;
      color: #fff;
    `}
`;

export const MenuItemTableNumber = styled.p`
  width: 35px;
  color: #333333;
  font-family: Arial, Helvetica, sans-serif;
`;

export const MenuItemBuyer = styled.p`
  width: 45px;
  font-size: 14px;
`;

export const MenuItemCheck = styled.p`
  width: 35px;

  svg {
    margin-left: 12px;
  }
`;

export const ModalDescription = styled.div`
  width: 200px;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const MenuItemOrderTime = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
`;


export const SelectArea = styled.div`
  margin-top: 28px;
  padding-left: 10px;
  padding-bottom: 10px;
  width: 60%;

  background-color: #fff;

  label {
    font-size: 14px;
    font-weight: bold;
  }
`
