import React, { useState, useEffect, useRef } from 'react';

import { useAuth } from '../../context/auth';
import Select from 'react-select'

import api from '../../services/api';

import Footer from '../../components/Footer';
import WaiterTicketsItem from './WaiterTicketsItem'

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  HeaderAction,
  ItemHeader,
  Table, Client, Value, Paid, Action

} from './styles';
import { useCallback } from 'react';
import { useWaiterTickets } from '../../context/waiterTicketContext';

const WaiterTickets = props => {
  const { restaurantFantasyName } = useAuth();
  const [tickets, setTickets] = useState([])

  const footerRef = useRef(null)
  const { waiterTickets } = useWaiterTickets()


  // const getTickets = useCallback(async()=>{
  //   const response = await api.get("/waiter/help-requests")

  //   setPixes(response.data)


  // }, [])

  // useEffect(()=>{
  //   getTickets()
  // }, [getTickets])

  return (
    <Container>
      <Content>
        <HeaderAction>
          <header>
            <h1>{restaurantFantasyName}</h1>
            <div>Solicitações de ajuda.</div>
          </header>

        </HeaderAction>

        <MenuContent>
          <MenuItem>
            <ItemHeader>
              <Table>Hora</Table>
              <Table>Mesa</Table>
              <Client>Cliente</Client>
              <Value>Comentário</Value>
              <Action>Checar</Action>

            </ItemHeader>
            {waiterTickets && waiterTickets.length > 0 && waiterTickets.map(ticket => (
              <WaiterTicketsItem ticket={ticket} />
            ))}
          </MenuItem>
        </MenuContent>

        <Footer ref={footerRef} />
      </Content>
    </Container>
  );
};

export default WaiterTickets
