import styled, { css } from 'styled-components';

export const Container = styled.li`
  height: 90px;
  width: 103px ;
  text-decoration: none;
  background: #ffa814;
  color: #FFF;
  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: column ;

  ${props =>
    props.status === 'open' &&
    css`
      background: #ff2b3a;
      color: #FFF;
    `};

    ${props =>
    props.status === 'none' &&
    css`
      background: none;
      color: #FFF;
    `};

    ${props =>
    props.status === true &&
    css`
       background: #2EC9B7;
      color: #FFF;
    `};

  ${props =>
    props.status === 'available' &&
    css`
      background: #2EC9B7;
      color: #FFF;
    `};

  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  border-radius: 7px;
  list-style: none;
`;
