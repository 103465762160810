import React, { useState } from "react";
import formatValue from '../../utils/formatValue';
import formatTime from '../../utils/formatTime';

import { uuid } from 'uuidv4'
import CancelationComponent from '../../components/CancelationComponent';
import ModalComponent from '../../components/ModalComponent';
import { FiX } from "react-icons/fi";

const ProductItemTableAccount = (props) => {
  const { basket, bill, item, fetchData } = props
  const [cancelationModalVisible, setCancelationModalVisible] = useState(false)
  const handleOpenCancelComponent = () => {
    setCancelationModalVisible(true)
  };
  return (
    <>
      {cancelationModalVisible && (
        <ModalComponent modalVisible={cancelationModalVisible} setModalVisible={setCancelationModalVisible} title="Cancelamento">
          <CancelationComponent
            setCancelationModalVisible={setCancelationModalVisible}
            order_basket_id={basket.id}
            order_id={item.id}
            product={item}
            fetchData={fetchData}
          />
        </ModalComponent>
      )}
      <li key={uuid()} style={{marginBottom: 10}}>
        <div
          className={
            bill.status === 'finished'
              ? 'bill_finished'
              : ''
          }
        >
          <p>{formatTime(basket.start_time)}</p>
          <p>{item.amount} x</p>
          <p>{item.product.name}</p>
        </div>

        <div>
          <p
            className={
              bill.status === 'finished'
                ? 'bill_finished'
                : ''
            }
          >
            {formatValue(item.total_price)}
          </p>
          <button style={{ background: '#fff', width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', border: `1px solid #FF2C3A`, borderRadius: 5 }} onClick={handleOpenCancelComponent}>
           <FiX size={20} color="#FF2C3A"/>
          </button>
        </div>
      </li>
    </>
  )
}

export default ProductItemTableAccount