import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #fff;
    color: #333;
    -webkit-font-smoothing: antialiased;
    font-family: 'Poppins', sans-serif;
  }

#root{
  height: 100vh;
}

  body, input, button {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button{
    cursor: pointer;
  }

  .btn-primary {
    width: 100%;
  max-width: 414px;
    height: 48px;
    background: linear-gradient(144deg, rgba(251,74,32,1) 28%, rgba(250,5,97,1) 100%);
    color: #fff;
    border-radius: 7px;
    border: none;
  }

  .btn-secondary {
    width: 100%;
    max-width: 414px;
    height: 48px;
    color: #fb4a20;
    background: transparent;
    border-radius: 7px;
    border: 1px solid #fb4a20;
    margin-top: 10px;
  }
`;
