import React, { useRef } from "react";
import { Header, ModalWrapper, Overlay, CloseButton } from "./styles";
import { FiX } from "react-icons/fi";

const ModalComponent = ({ modalVisible, setModalVisible, children, title, overlayTouchable }) => {
  const overlayRef = useRef(null);

  const handleOverlayPress = (event) => {
    if (event.target === overlayRef.current) {
      setModalVisible(false);
    }
  };

  return (
    <div>
      {modalVisible && (
        <Overlay ref={overlayRef} onClick={handleOverlayPress}>
          <ModalWrapper>
            <Header>
              <h2>{title}</h2>
              <CloseButton onClick={() => setModalVisible(false)}>
                <FiX size={20} color="#FF2C3A"/>
              </CloseButton>
            </Header>
            {children}
          </ModalWrapper>
        </Overlay>
      )}
    </div>
  );
};

export default ModalComponent;