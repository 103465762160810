import React, { useState } from 'react';
import { FiEye, FiSmartphone, FiCheckSquare, FiXCircle } from 'react-icons/fi';
import { Container, Content, ItemList, Table, Client, Value, Paid, Action } from './styles';
import formatCompleteDate from '../../../services/formatCompleteDate'
import { useCallback } from 'react';
import api from '../../../services/api';
import Modal from 'react-modal';
import formatTime from '../../../utils/formatTime';
import { useAuth } from '../../../context/auth';

function WaiterTicketsItem({ticket}) {

  const {restaurantUseCommand} = useAuth();
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
      border: 'none'
    },
  };

  const [isModalCommentOpen, setIsModalcommentOpen] = useState(false)

  function toggleOpenComment(){
    setIsModalcommentOpen(!isModalCommentOpen)
  }

  const checkTickets = useCallback(async()=>{
    await api.put(`/waiter/help-requests/check/${ticket.id}`)
  }, [ticket])


  return (
    <Container>
      <Content>
        <Modal isOpen={isModalCommentOpen} onRequestClose={toggleOpenComment} style={customStyles}>
        <strong >Comentário:</strong>
          <p style={{marginTop: '20px'}}>{ticket?.comment}</p>
        </Modal>
        <ItemList>
        <Table>{formatTime(ticket.created_at)}</Table>
        {restaurantUseCommand ?  <Table>{ticket.command_table_number}</Table>:   <Table>{ticket.table_number}</Table> }

          <Client>{ticket.buyer?.phone} {` - ${ticket.buyer?.name || ''}`}</Client>
          <Value><FiEye onClick={toggleOpenComment} color='darkGreen' size={17} style={{cursor: 'pointer'}}/></Value>
          <Action>{ticket.checked ? (<span>Já atendido</span> ) : ( <FiCheckSquare size={20} color='green' onClick={checkTickets}/>)} </Action>
        </ItemList>
      </Content>

    </Container>
  )
}

export default WaiterTicketsItem;
