import styled from 'styled-components';

export const Container = styled.div`
  /* margin: 0 auto; */
  background: #fff;
  width: 100%;
  max-width: 410px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  height: 300px;
  margin-top: 50px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 414px;
  height: 300px;
position: relative ;
  display: flex;
  flex-direction: column;
  /* padding-bottom: 65px; */


  a {
    text-decoration: none;
    color: #fb4a20;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 700;
  }
`;

export const Footer = styled.footer`
  height: 58px;
background-color: #fff ;
  position: fixed;

  bottom: 10px;
  width: 100%;

  button {

    width: 90%;
    max-width: 410px;
border-radius: 24px ;
    height: 48px;
    background: linear-gradient(
      144deg,
      #FF2B3A 28%,
      #FF2E15 100%
    );
    color: #fff;
    margin-top: 10px ;
    margin-bottom: 10px ;
    border: none;
    margin-left: 15px ;

    span {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      margin-left: 8px;
      margin-top: 3px;
    }
  }
`;

export const ProductName = styled.h1`
  margin-top: 30px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
`;

export const ImageProduct = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;


  overflow: hidden;
width: 100% ;
  max-width: 410px;
  height: 250px;

  img {
    width: 100%;
  }
`;

export const DescriptionProduct = styled.div`
  margin-top: 8px;
  padding: 10px;
  padding-bottom: 10px;

  h1 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 18px ;
  }

  span {
    font-size: 14px;



  }
`;







