import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { uuid } from 'uuidv4'
import { useAuth } from '../../context/auth';
import api from '../../services/api';
import formatValue from '../../utils/formatValue';

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  MenuItemHeader,
  MenuItemBody,
  Footer,
  Orders,
  ServiceTax,
  PriceText,
  PriceTextArea,
  PriceTotalText,
  ButtonsArea,
  ClosingButton,
  ButtonOutline,
  PrintButton,
  ModalFooter,
  ConfirmButton,
  CancelButton,
} from './styles';
import { FaAngleDown, FaPrint } from 'react-icons/fa';

import Modal from 'react-modal';
import ProductItemTableAccount from '../../components/ProductItemTableAccount';

const TableAccount = ({ totalTable, fetchData }) => {
  const { sessionKey, restaurantServiceTax, restaurantWithWaiterCloseBill } = useAuth();

  const [tablePayments, setTotalPayments] = useState('');


  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
      border: 'none'
    },
  };

  const history = useHistory();

  const [isModalCloseAccountOpened, setIsModalCloseAccountOpened] = useState(false)

  function toggleCloseAccountModal() {
    setIsModalCloseAccountOpened(!isModalCloseAccountOpened)
  }

  async function handleCloseAccount() {
    try {
      await api.put('waiter/close-table-bill', {
        key: sessionKey,
        from_gm: true,
      });

      openModalPrintMessage("Comanda fechada e enviada pra impressora com sucesso.")

      history.push('/restauranttables');
    } catch (eror) {
      openModalPrintMessage("Erro ao fechar comanda.")
      console.log(eror);
    }
  }

  useEffect(() => {
    async function getTableData() {
      const res = await api.get('waiter/table-sessions/payments', {
        params: { session_key: sessionKey },
      });

      setTotalPayments(res.data.total_value);

    }
    getTableData();
  }, [sessionKey]);

  const totalPrice = totalTable.reduce(
    (acum, curr) => acum + curr?.total_price,
    0,
  );

  const totalPriceTax = totalTable.reduce(
    (acum, curr) => acum + curr?.total_service_price,
    0,
  );

  const [arrayCollapse, setArrayCollapse] = useState([])

  function collapseId(id) {
    setArrayCollapse(prevArray => {
      const index = prevArray.indexOf(id);

      if (index === -1) {
        return [...prevArray, id];
      } else {
        const newArray = [...prevArray];
        newArray.splice(index, 1);
        return newArray;
      }
    });
  }

  const [modalPrintMessageOpen, setModalPrintMessageOpen] = useState(false)
  const [modalPrintMessage, setModalPrintMessage] = useState("")


  function openModalPrintMessage(message) {
    setModalPrintMessage(message)
    setModalPrintMessageOpen(true)
  }

  function toggleModalPrintMessage() {
    setModalPrintMessageOpen(!modalPrintMessageOpen)
  }

  async function handlePrintAccount() {
    try {
      await api.post("/waiter/printers/printer-queue", {
        table_session_id: totalTable[0].id,

      })
      openModalPrintMessage("Comanda enviada pra impressora com sucesso.")

    } catch (error) {
      openModalPrintMessage("Erro ao imprimir comanda")
      console.log(error.message)
    };
  }

  async function handlePrintAaInddividualccount(id) {
    try {
      await api.post("/waiter/printers/printer-queue", {
        individual_bill_id: id,
      })
      openModalPrintMessage("Comanda enviada pra impressora com sucesso.")

    } catch (error) {
      openModalPrintMessage("Erro ao imprimir comanda")
      console.log(error.message)
    }

  }

  async function handleCloseAccountToOnGoing() {
    try {
      await api.put('waiter/table-sessions/close-table-bill', {
        session_key: sessionKey,
      });

      history.push('/restauranttables');
    } catch (eror) {
      console.log(eror);
    }
  }

  return (
    <Container>
      <Content>
        <Modal isOpen={modalPrintMessageOpen} onRequestClose={toggleModalPrintMessage}
          style={customStyles}
          contentLabel="Modal de Chamado de Cliente">

          <p>{modalPrintMessage}</p>
          <ModalFooter>
            <ConfirmButton onClick={toggleModalPrintMessage} style={{ width: '100%', display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Ok, ciente!</ConfirmButton>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isModalCloseAccountOpened} onRequestClose={toggleCloseAccountModal}
          style={customStyles}
          contentLabel="Modal de Chamado de Cliente">

          <p>Tem certeza que deseja fechar a comanda?</p>
          <ModalFooter>
            <CancelButton onClick={toggleCloseAccountModal} style={{ width: '100%', display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Cancelar</CancelButton>
            <ConfirmButton onClick={handleCloseAccount} style={{ width: '100%', display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Sim, fechar!</ConfirmButton>
          </ModalFooter>
        </Modal>

        <MenuContent>
          <MenuItem>
            {totalTable && totalTable.map(table => (
              <Orders key={uuid()}>
                {table?.bills.map(bill => (
                  <>
                    <MenuItemHeader >
                      <div>
                        {bill?.buyer ? (
                          <>
                            <FaAngleDown onClick={() => { collapseId(bill.id) }} color='#C4C4C4' size={21} style={{ transform: arrayCollapse.includes(bill.id) ? "rotate(180deg)" : "rotate(0deg)" }} />

                            {/* {restaurantWithWaiterCloseBill && (<PrintButton onClick={() => { handlePrintAaInddividualccount(bill.id) }}>
                              <FaPrint color='#FF1F2B' />
                            </PrintButton>)} */}

                            <span onClick={() => { collapseId(bill.id) 
                            }}>{bill.buyer.name || bill.buyer.phone }</span>
                            <span style={{ width: restaurantWithWaiterCloseBill ? 90 : 140, height: 35 }} onClick={() => { collapseId(bill.id) }}></span>
                          </>
                        ) : (
                          <>
                            <FaAngleDown onClick={() => { collapseId(bill.id) }} color='#C4C4C4' size={21} style={{ transform: arrayCollapse.includes(bill.id) ? "rotate(180deg)" : "rotate(0deg)" }} />
                            {/* {restaurantWithWaiterCloseBill && (<PrintButton onClick={() => { handlePrintAaInddividualccount(bill.id) }}>
                              <FaPrint color='#FF1F2B' />
                            </PrintButton>)} */}
                            <span onClick={() => { collapseId(bill.id) }} > {bill?.waiter.name}</span>
                            <span style={{ width: restaurantWithWaiterCloseBill ? 90 : 140, height: 35 }} onClick={() => { collapseId(bill.id) }}></span>
                          </>
                        )}
                      </div>
                      <p onClick={() => { collapseId(bill.id) }}>{formatValue(bill?.total_service_price)}</p>
                    </MenuItemHeader>

                    <MenuItemBody key={uuid()}>
                      <ul>
                        <div key={uuid()} style={{ display: arrayCollapse.includes(bill.id) ? "block" : "none" }}>
                          {bill.order_baskets.map(basket => (
                            <React.Fragment key={uuid()}>
                              {basket.orders.map(item => (
                                <ProductItemTableAccount fetchData={fetchData} key={uuid()} item={item} basket={basket} bill={bill} />
                              ))}
                            </React.Fragment>
                          ))}
                          {restaurantServiceTax.toString() === 'true' && (
                            <ServiceTax>
                              <div
                                className={
                                  bill.status === 'finished'
                                    ? 'bill_finished'
                                    : ''
                                }
                              >
                                <p>Serviço (10%)</p>
                                <p>
                                  {formatValue(
                                    bill.total_service_price - bill.total_price,
                                  )}
                                </p>
                              </div>
                            </ServiceTax>
                          )}
                        </div >
                      </ul>
                    </MenuItemBody>
                  </>
                ))}
              </Orders>
            ))}
          </MenuItem>
        </MenuContent>

        <Footer>
          {restaurantServiceTax.toString() === 'true' ? (
            <PriceTextArea> <PriceTotalText>Total</PriceTotalText> <PriceText>{formatValue(totalPriceTax - tablePayments)}</PriceText></PriceTextArea>
          ) : (
            <PriceTextArea><PriceTotalText>Total</PriceTotalText> <PriceText>{formatValue(totalPrice - tablePayments)}</PriceText></PriceTextArea>
          )}

          {restaurantWithWaiterCloseBill ? (
            <ButtonsArea>
              <ButtonOutline>
                <FaPrint size={20} color="#2EC9B7" onClick={handlePrintAccount} />
                Espelho
              </ButtonOutline>
              <ClosingButton onClick={toggleCloseAccountModal}>
                Fechar comanda
              </ClosingButton>
            </ButtonsArea>
          ) : (
            <ButtonsArea>

              <ClosingButton onClick={handleCloseAccountToOnGoing} style={{ width: "100%" }}>
                Fechar comanda
              </ClosingButton>
            </ButtonsArea>
          )}

        </Footer>
      </Content>
    </Container>
  );
};

export default TableAccount;
