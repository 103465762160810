import React, {
  createContext,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../auth';

const PosContext = createContext();

const PosProvider = ({ children }) => {
  const history = useHistory();

  const { websocketManager, stoneTransactionId } = useAuth();

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(4, (data) => {
        console.log('Pos: socket new data', data);
        if (data.type === 'pos-paid'){
          if(data.item.transaction_id === stoneTransactionId){
            history.push('/restauranttables');
          }
        }
    })
  }}, [websocketManager, stoneTransactionId]);


  const value = useMemo(() => ({ }), []);

  return (
    <PosContext.Provider value={value}>
      {children}
    </PosContext.Provider>
  );
};

function usePos() {
  const context = useContext(PosContext);

  if (!context) {
    throw new Error('usePos must be used within an PosContext');
  }

  return context;
}

export { PosProvider, usePos };
