import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';
import NumberFormat from 'react-number-format';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderAction = styled.div`
  width: 100%;
  max-width: 410px;

  padding: 10px;

  position: fixed;
  top: 0;

  background: #fff;
  border-bottom: 1px solid #f8f8f8;

  header {
    color: #000;
    font-weight: bold;

    h1 {
      color: #000;
      font-weight: bold;
    }
  }
`;
export const ButtonEditPeople = styled.button`
  background-color: transparent;
  border: none;
  margin-top: 20px;
  span {
    color: #666666;
    text-decoration: underline;
    font-weight: bold;
  }
`;
export const Nav = styled.nav`
  width: 100%;
  max-width: 410px;

  position: fixed;
  top: 94px;
  margin-left: 16px;

  display: flex;
  justify-content: center;

  li {
    display: inline-flex;
    list-style: none;
    margin-right: 24px;
    color: #fb4a20;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;

    & + li {
      border-left: 1px solid #fb4a2070;
      padding-left: 24px;
    }

    &:hover {
      text-decoration: underline;
    }
    svg {
      margin-right: 4px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 414px;
  height: 50px;
  display: flex;
  flex-direction: column;
  padding-bottom: 65px;
  margin-top: 65px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }

    a {
      color: #33333370;
      font-size: 12px;
      font-weight: 900;
    }
  }
`;

export const MenuContent = styled.div`
  background: #fff;
  border-radius: 7px;
  padding-bottom: 170px;
`;

export const RouterDomLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${shade(0.1, '#333333')};
`;

export const NumberFormatStyled = styled(NumberFormat)`
  text-align: center;
  height: 32px;
  width: calc(100% - 54px);
  max-width: 130px;
  min-width: 120px;
  padding: 2px;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  &:focus {
    border: 1px solid #656565;
    border-radius: 8px;
  }
`;

export const PeopleArea = styled.div`
  color: #666666;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const SubmitStyledButton = styled.button`
  height: 32px;
  width: 32px;
  border-radius: 8px;
  border: none;
  margin-left: 8px;
  padding: 8px;
  align-items: center;

  background-color: #0ccab4;

  svg {
    color: #fff;
  }
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid #bfbfbf;
    svg {
      filter: opacity(0.3);
      color: #333;
    }
  }
`;
