import React from 'react';

import { Container } from './styles';

const Table = ({ status, children, ...rest }) => {
  return (
    <Container status={status} {...rest}>
      {children}
    </Container>
  );
};

export default Table;
