import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TextRegular = styled.p`
  margin-top: 0;!important;
  color: #3D3D3D;
  font-family: 'Poppins';
  font-size: 14px;
`;

export const TextSemiBold = styled.p`
  margin-top: 0;
  color: #3D3D3D;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
`;

export const DropdownContainer = styled.select`
  width: 100%;
  background-color: #fff;
  border-color: #CDCDCD;
  border-radius: 10px;
  border-width: 1px;
  height: 40px;
  padding: 10px 20px;
`;

export const DropdownText = styled.p`
  color: #6C6C6C;
  font-family: 'Poppins';
  font-size: 14px;
`;

export const CancelButton = styled.button`
  width: 100%;
  align-items: center;
  background-color: #FF2C3A;
  border-radius: 100px;
  color: #fff;
  font-family: 'Poppins';
  font-size: 14px;
  height: 40px;
  justify-content: center;
  padding: 5px 20px;
  border: none;
`;