import styled from 'styled-components';
import { Modal } from 'reactstrap';
export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;


export const SearchBar = styled.div`
  display: block;
  justify-content: flex-start;
  align-items: center;
  height: 40px;

  position: relative;
  margin-top: 10px;
  width: 100%;

  svg {
    color: #d3d3d3;
  }

  input {
    width: 100%;
    font-size: 12px;
    background: #fff;
    color: #4d4d4c;
    border: 2px solid #d3d3d3;
    margin-top: -10px;
    border-radius: 7px;
    padding: 8px;
    padding-left: 28px;
    ::placeholder {
      color: #4d4d4c;
    }
  }
`;

export const ButtonSearchInput = styled.div`
  background: transparent;
  border: none;

  border-radius: 8px;
  position: absolute;
  left: 8px;
  bottom: 14px;
`;

export const ButtonDeleteInput = styled.button`
  background: transparent;
  border: none;

  border-radius: 8px;
  position: absolute;
  right: 20px;
  bottom: 12px;
`;


export const ModalStyled = styled(Modal)`
     background: #fff;
      top: 6%;
      left: 50%;
      right: auto;
      bottom: auto;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      border-radius: 7px;
      position: fixed;
      z-index: 1062;
      overflow-x: hidden;
      overflow-y: auto;
      outline: 0;
      width: 95%;

      animation-name: transtion;
  animation-duration: 0.5s;

  @keyframes transtion {
  0%   {left: -50%}
  100% {left: 50%}
}
`

export const Content = styled.div`
  width: 100%;
  max-width: 414px;
  height: 100%;
position: relative ;
  display: flex;
  flex-direction: column;
  /* padding-bottom: 65px; */

  a {
    text-decoration: none;
    color: #fb4a20;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 700;
  }
`;

export const Footer = styled.footer`
  height: 58px;
background-color: #fff ;
  position: fixed;
  bottom: 0px;
  width: 100%;

  button {
    margin-left: 20px;
    width: 90%;
    max-width: 410px;
border-radius: 24px ;
    height: 48px;
    background: #FF2c3A;
    color: #fff;
      font-weight: bold;
    border: none;

    span {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      margin-left: 8px;
      margin-top: 3px;
    }
  }
`;

export const ProductName = styled.h1`
  margin-top: 30px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
`;

export const ImageProduct = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-top: -5px;

  overflow: hidden;

  max-width: 410px;
  height: 250px;

  img {
    width: 100%;
  }
`;

export const DescriptionProduct = styled.div`
  margin-top: 8px;
  padding: 10px;
  padding-bottom: 0;

  h1 {
    font-size: 18px;
    font-weight: 700;
  }

  span {
    font-size: 14px;
  }
`;

export const AddItemsProduct = styled.div`
  padding-right: 10px;
  padding-left: 10px;

  padding-bottom: 35px;
  div {
    li {
      width: 100%;

      display: flex;
      justify-content: space-between;

      padding-bottom: 10px;
      padding-right: 10px;
      padding-left: 10px;

      align-items: center;

      & + li {
        border-top: 1px solid #33333320;
        padding-top: 10px;
      }
    }
  }
`;

export const EditAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px ;
  padding-right: 10px ;

  span {
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }
`;

export const EditActionButtons = styled.div`
  width: 100px;

  justify-content: space-around;
  align-items: center !important;
  display: flex;
  color: #fb4a20;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;

  p {
    margin-left: 10px ;
    margin-right: 10px ;
  }


  button {
    border: 0;
    background: transparent;
  }

  input {
    width: 100px;
    border-radius: 5px;
    padding: 7px 14px;
    border: 1px solid #33333380;
  }
`;

export const ComplementsHeaderProduct = styled.div`
  margin-top: 15px;
  background: #f8f8f8;
  border-radius: 7px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > p {
    font-size: 10px;
    background: #fb4a20;
    color: #fff;
    border-radius: 7px;
    padding: 4px;
  }
`;

export const ComplementItem = styled.div`
  width: 100%;
  max-width: 414px;
  display: flex;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`;

export const ComplementItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 98%;
  padding-left: 10px;
`;

export const ComplementItemDescriptionLimit = styled.span`
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  color: #fb4a20;
`;

export const ComplementItemAmount = styled.div`
  display: flex;
  align-items: center;
  height: 30px;

  p {
    margin-right: 10px;
    margin-left: 10px;

    color: #fb4a20;
  }
`;

export const InformationText = styled.div`
  margin-top: 20px;
  margin-bottom: 35px;

  p {
    display: flex;
    align-items: center;
    margin-left: 20px;

    svg {
      margin-right: 10px;
    }
  }

  input {
    width: 90%;
    max-width: 414px;
    height: 48px;

    margin-top: 10px;
    margin-left: 20px;
    text-align: center;

    color: #fb4a20;
    border-radius: 5px;
    border: 1px solid #fb4a20cc;

    ::placeholder {
      color: #fb4a20cc;
      font-size: 14px;
    }
  }
`;

export const ModalTitle = styled.div`
  padding: 20px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 7px;
  background: '#fff';
  span {
    text-align: center;
    font-weight: bold;
  }
  border: 1px solid #FF2C3A;


`;



export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 14px 10px;
  font-size: 14px;
  color: #fff;
  background: #FF2C3A;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
  font-weight: bold;
`;

export const QuestionSubtitle = styled.span`
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
`;
