import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import { v4 } from 'uuid';

import { useOrder } from '../order/index';

const CartContext = createContext();


const CartProvider = ({ children }) => {
  const { addToOrder, addToOrderBalcony, addToOrderPos } = useOrder();
  const [cart, setCart] = useState(() => {
    const cartStorage = localStorage.getItem('@waitermanager:cart');

    if (cartStorage) {
      return JSON.parse(cartStorage);
    }

    return [];
  });

  useEffect(() => {
    localStorage.setItem('@waitermanager:cart', JSON.stringify(cart));
  }, [cart]);

  const addCartToOrder = useCallback((command_table_number) => {
    addToOrder(cart, command_table_number, closeCart);
  }, [cart, addToOrder]);

  const addCartToOrderBalcony = useCallback(() => {
    addToOrderBalcony(cart, closeCart);
  }, [cart, addToOrderBalcony]);

  const addCartToOrderPos = useCallback((type) => {
    addToOrderPos(cart, closeCart, type);
  }, [cart, addToOrderPos]);

  const closeCart = useCallback(() => {
    localStorage.removeItem('@waitermanager:cart');

    setCart([]);
  }, []);

  const increment = useCallback(
    (id, orderingId) => {
      const newCart = [...cart];

      if (orderingId) {
        const findCart = newCart.findIndex(
          (item) => item.ordering_system_id === orderingId
        );

        newCart[findCart].amount += 1;

        setCart(newCart);
      } else {
        const findCart = newCart.findIndex((item) => item.intern_id === id);

        newCart[findCart].amount += 1;

        setCart(newCart);
      }
    },
    [cart]
  );

  const decrement = useCallback(
    (id, orderingId) => {
      const newCart = [...cart];

      if (orderingId) {
        const findCart = newCart.findIndex(
          (item) => item.ordering_system_id === orderingId
        );

        if (newCart[findCart].amount > 1) {
          newCart[findCart].amount -= 1;
        }

        setCart(newCart);
      } else {
        const findCart = newCart.findIndex((item) => item.id === id);

        if (newCart[findCart].amount > 1) {
          newCart[findCart].amount -= 1;
        }

        setCart(newCart);
      }
    },
    [cart]
  );
  const addToCart = useCallback(
    (basket) => {
      if (
        cart.length > 0 &&
        basket.complementsCategory.length < 1 &&
        !basket.isProductWeight
      ) {
        const productExist = cart.filter(
          (product) =>
            product.id === basket.product.id &&
            product.complement_categories.length < 1 &&
            !product.observation
        );

        if (productExist.length > 0) {
          increment(productExist[0].intern_id, null);
        } else {
          setCart([
            ...cart,
            {
              basket_id: v4(),
              id: basket.product.id,
              ordering_system_id: v4(),
              name: basket.product.name,
              price: basket.product.price,
              image: basket.product.image,
              amount: basket.amount,
              observation: basket.observation,
              complement_categories: basket.complementsCategory,
              use_weight: basket.isProductWeight || false,
              weight: basket.inputProductWeight || null,
              intern_id: basket.product.id + 1,
              is_unitary: basket.product.is_unitary
            },
          ]);
        }
      } else {
        setCart([
          ...cart,
          {
            basket_id: v4(),
            id: basket.product.id,
            ordering_system_id: v4(),
            name: basket.product.name,
            price: basket.product.price,
            image: basket.product.image,
            amount: basket.amount,
            observation: basket.observation,
            complement_categories: basket.complementsCategory,
            use_weight: basket.isProductWeight || false,
            weight: basket.inputProductWeight || null,
            intern_id: basket.product.id + 1,
            is_unitary: basket.product.is_unitary
          },
        ]);
      }
    },
    [cart, increment]
  );

  const removeFromCart = useCallback(
    id => {
      const newCart = [...cart];
      const productId = newCart.findIndex(item => item.basket_id === id);

      if (productId >= 0) {
        const cartFiltered = newCart.filter(
          product => product.basket_id !== id,
        );

        setCart(cartFiltered);
      } else {
        console.log('Produto nao existe');
      }
    },
    [cart],
  );

  const addObservation = useCallback(
    (id, ordering_system_id, message) => {
      const newCart = [...cart];

      if (ordering_system_id) {
        const findCart = newCart.findIndex(
          (item) => item.ordering_system_id === ordering_system_id
        );

        newCart[findCart].observation = message;

        setCart(newCart);
      } else {
        const findCart = newCart.findIndex((item) => item.id === id);

        newCart[findCart].observation = message;

        setCart(newCart);
      }
    },
    [cart]
  );


  const value = useMemo(
    () => ({
      addToCart,
      decrement,
      increment,
      cart,
      closeCart,
      addCartToOrder,
      addCartToOrderBalcony,
      addCartToOrderPos,
      removeFromCart,
      addObservation
    }),
    [
      addToCart,
      closeCart,
      decrement,
      increment,
      cart,
      addCartToOrder,
      addCartToOrderBalcony,
      addCartToOrderPos,
      removeFromCart,
      addObservation
    ],
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

const useCart = () => {
  const context = useContext(CartContext);

  if (!context) {
    console.log('useCart must be within a provider');
  }

  return context;
};

export { CartProvider, useCart };
