import React, { useState } from 'react';
import Modal from 'react-modal';

import formatTime from '../../../utils/formatTime';

import { FiEye, FiSmartphone, FiCheckSquare, FiXCircle } from 'react-icons/fi';

import api from '../../../services/api';

import {
  ModalDescription,
  MenuItemBody,
  MenuItemDescription,
  AddToCart,
  MenuItemTableNumber,
  MenuItemBuyer,
  MenuItemCheck,
  MenuItemOrderTime,
  ItemComplements,
  ItemComplementsQuestion,
  ItemComplementsName,
  ItemBuyer,
  ItemOrderId,
} from './styles';
import { useOrders } from '../../../context/ordersContext';

const OrderClosedItem = ({ item }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const {uncheckItem} = useOrders()

  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }



  return (
    <MenuItemBody key={item?.id}>
      <AddToCart>
      {item?.basket?.bill?.session.table && (
        <MenuItemTableNumber>
       {item?.basket?.bill?.session.table.table_type === 'delivery' ? 'Deliv.' : item?.basket.bill.session.table.table_number}
      </MenuItemTableNumber>
      )}

        {item?.basket.bill.buyer ? (
          <MenuItemBuyer>
           <span>Cliente</span>
          </MenuItemBuyer>
        ) : (
          <MenuItemBuyer>{item?.basket.bill.waiter.name}</MenuItemBuyer>
        )}
        <MenuItemOrderTime>
          {item?.basket && formatTime(item?.basket?.start_time || item?.basket?.start_item)}
        </MenuItemOrderTime>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalDescription>
            <ItemOrderId>
              <p>{item?.basket.basket_id}</p>
            </ItemOrderId>
            {!!item?.basket.bill.buyer ? (
              <ItemBuyer>
                <p>{item?.basket.bill.buyer.phone}</p>
              </ItemBuyer>
            ) : (
              <ItemBuyer>
                <p>{item?.basket.bill.waiter.name}</p>
              </ItemBuyer>
            )}

            {item?.complement_categories.map(category => (
              <ItemComplements key={category.id}>
                <ItemComplementsQuestion>
                  {category.complement_category.question}
                </ItemComplementsQuestion>
                {category.order_complements.map(item => (
                  <ItemComplementsName key={item.id}>
                    {item?.amount > 1 && <p>{item?.amount}x</p>}{' '}
                    <p>{item?.complement.name}</p>
                  </ItemComplementsName>
                ))}
              </ItemComplements>
            ))}

            <FiXCircle onClick={closeModal} />
          </ModalDescription>
        </Modal>
        <FiEye onClick={openModal} color="#33b5e5" />

        <MenuItemDescription>
        {item?.use_weight ? (<strong>{`${item?.weight} kg ${item?.product.name}`}</strong>) : (<strong>{`${item?.amount}x ${item?.product.name}`}</strong>)}
        </MenuItemDescription>
        <MenuItemCheck
          onClick={() => {
            uncheckItem(item?.id);
          }}
        >
          <FiCheckSquare color="green" size={22} />
        </MenuItemCheck>
      </AddToCart>
    </MenuItemBody>
  );
};

export default OrderClosedItem;
