import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import { uuid } from 'uuidv4';

import { useAuth } from '../auth';

const OrderContext = createContext();

const OrderProvider = ({ children }) => {
  const { tableLogin, balconyLogin, posLogin } = useAuth();

  const [orders, setOrders] = useState(() => {
    const orderStorage = localStorage.getItem('@waitermanager:order');

    if (orderStorage) {
      return JSON.parse(orderStorage);
    }

    return [];
  });

  useEffect(() => {
    localStorage.setItem('@waitermanager:order', JSON.stringify(orders));
  }, [orders]);

  const addToOrder = useCallback(
    async (cart, command_table_number, closeCart) => {
      setOrders([...orders, { order: [...cart], id: uuid() }]);

      const lastOrder = [...cart];
      tableLogin(lastOrder, command_table_number, closeCart);
    },

    [orders, tableLogin],
  );

  const addToOrderBalcony = useCallback(
    async (cart, closeCart) => {
      setOrders([...orders, { order: [...cart], id: uuid() }]);

      const lastOrder = [...cart];

      balconyLogin(lastOrder, closeCart);
    },

    [orders, balconyLogin],
  );

  const addToOrderPos = useCallback(
    async (cart, closeCart, type) => {
      setOrders([...orders, { order: [...cart], id: uuid() }]);

      const lastOrder = [...cart];

      posLogin(lastOrder, closeCart, type);
    },

    [orders, balconyLogin],
  );

  const closeOrder = useCallback(() => {
    localStorage.removeItem('@waitermanager:order');
    localStorage.removeItem('@waitermanager:cart');
    localStorage.removeItem('@waitermanager:basketId');

    setOrders([]);
  }, []);

  const value = useMemo(
    () => ({
      addToOrder,
      addToOrderBalcony,
      addToOrderPos,
      orders,
      closeOrder,
    }),
    [addToOrder, orders, closeOrder, addToOrderBalcony, addToOrderPos],
  );

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};

function useOrder() {
  const context = useContext(OrderContext);

  if (!context) {
    console.log('useOrder must be within a provider');
  }

  return context;
}

export { OrderProvider, useOrder };
