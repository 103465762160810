import styled from 'styled-components';
import { ReactComponent as LogoImg } from '../../assets/images/iconTake.svg';
import {Form} from '@unform/web'
import { FiEye } from 'react-icons/fi';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  p {
    text-align: center;
  }
`;

export const Title = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;

  p {
    color: #333;
    margin-top: 20px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const Logo = styled(LogoImg)`
  .cls-1 {
    fill: #fb4a20;
  }

  width: 80px;
`;

export const StyledForm = styled(Form)`
  margin-top: 40px;

  p {
    font-weight: bold;

  }

  input {
    width: 100%;
    max-width: 414px;
    height: 48px;
    color: #fb4a20;
    background: transparent;
    border-radius: 7px;
    border: 1px solid #fb4a20;
    margin-top: 10px;
    text-align: center;

    ::placeholder {
      text-align: center;
      color: #fb4a20;
    }
  }

  button {
    width: 100%;
    max-width: 414px;
    height: 48px;
    background: linear-gradient(
      144deg,
      rgba(251, 74, 32, 1) 28%,
      rgba(250, 5, 97, 1) 100%
    );
    color: #fff;
    border-radius: 7px;
    border: none;
    margin-top: 8px;
  }
`;



export const InputArea = styled.div`
  position: relative;
  width: 100%;

`;

export const FiEyeStyled = styled(FiEye)`
  position: absolute;
  right: 10px;
  bottom: 15px;
  cursor: pointer;
  color: rgba(251, 74, 32, 1);
`;
