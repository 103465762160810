import React from 'react';
import { useHistory } from 'react-router-dom';
import { FiCheckCircle } from 'react-icons/fi';

import { useAuth } from '../../context/auth';

import { Container, Content, Message, Footer } from './styles';

const ConfirmOrder = () => {
  const { basketId } = useAuth();

  const history = useHistory();

  function handleNavigate() {
    history.push('/restauranttables');
  }

  return (
    <Container>
      <Content>
        <Message>
          <FiCheckCircle size={136} color="#ff2c19" />
          <h1 style={{fontWeight: 'bold'}}>Pedido realizado</h1>
          <p style={{marginTop: '20px'}}>
            {`O Pedido ${basketId} já esta sendo preparado, e será entregue em breve.`}
          </p>{' '}

        </Message>
        <Footer>
          <button onClick={handleNavigate}>
            Entendi
          </button>
        </Footer>
      </Content>
    </Container>
  );
};

export default ConfirmOrder;
