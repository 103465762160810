import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';

import api from '../../services/api';

import { useAuth } from '../auth';

const TablesContext = createContext();

const TablesProvider = ({ children }) => {

  const { websocketManager, userToken } = useAuth();



  const [tablesAvailable, setTablesAvailable] = useState([]);
  const [tablesOccupied, setTablesOccupied] = useState([]);
  const [closingTables, setClosingTables] = useState([]);
  const [allTables, setAllTables] = useState([])

  const [balcony, setBalcony] = useState([]);
  const [posTable, setPosTable] = useState([]);

  const  fetchData = useCallback(async () => {
    try {
      const response = await api.get('/waiter/tables');

      const { tables, open_sessions, closing_sessions } = response.data;


      const balconyTables = tables.filter(table => table.table_type === 'balcony')
      const tablesFiltered = tables.filter(table => table.table_type !== 'balcony' && table.table_type !== 'pos')

      const posTable = tables.filter(table => table.table_type === 'pos');

      setBalcony(balconyTables);
      setPosTable(posTable);

      setTablesAvailable(tablesFiltered);
      setTablesOccupied(open_sessions);
      setClosingTables(closing_sessions);

      setAllTables([...balconyTables, ...tablesFiltered, ...closing_sessions, ...open_sessions])
    } catch (e) {
      console.log(e);
    }
  },[setBalcony, setTablesAvailable, setTablesOccupied, setAllTables ])

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(3, (data) => {
        console.log('RestaurantTables: socket new data', data);
        if (data.type === 'table-finished' || data.type === 'basket' || data.type === 'cancel-order' || data.type === 'table-closing' || data.type === 'new-transfer' || data.type === 'update-session-tax' || data.type === 'update-session-payments'){
          fetchData();
        }
    })

  }}, [websocketManager, fetchData]);


  useEffect(() => {
if(userToken){
  fetchData()
}


}, [fetchData, userToken]);

  const value = useMemo(() =>
    ({ allTables, tablesAvailable, tablesOccupied, balcony, closingTables, posTable }),
    [allTables, tablesAvailable, tablesOccupied, balcony, closingTables, posTable]);

  return (
    <TablesContext.Provider value={value}>
      {children}
    </TablesContext.Provider>
  );
};

function useTables() {
  const context = useContext(TablesContext);

  if (!context) {
    throw new Error('useTables must be used within an TablesProvider');
  }

  return context;
}

export { TablesProvider, useTables };
