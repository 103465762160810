import styled, { css } from 'styled-components';
import NotificationBadge from 'react-notification-badge';

export const Container = styled.div`
   margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
 `;


export const HeaderAction = styled.div`
  position: fixed;
  top: 0;
  padding-top: 14px;

  background: #fff;
  width: 100%;
  max-width: 410px;
  z-index: 10;

  header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;

    h1 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 12px;
    }
    > div {
      font-size: 16px;
      color: #FF2B3A;
      display: flex;
      align-items: center;

      span{
        color: #FF2B3A;
        font-weight: 500;
        margin-left: 8px;
      }
    }
  }

`;

export const RecentlyProduct = styled.div``;

export const ButtonDetails = styled.button`
  position: absolute ;
  background-color: #fff;
  border-radius: 8px 0 8px 0 ;
  border: 1px solid #33333380;
  width: 30px ;
  height: 30px ;
  left: 10px ;
`

export const RecentlyProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  color: #fff;
  border-radius: 7px;
  padding: 5px;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 414px;

  display: flex;
  flex-direction: column;
   padding-bottom: 70vh;
  margin-top: 65px;
`;

export const Footer = styled.footer`
  width: 100%;
  max-width: 410px;
  height: 65px;
  background: #fff;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #33333310;
  > div {
    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 10px;

      background: transparent;
      svg {
        margin-bottom: 4px;
      }
    }
    span {
      font-size: 12px;
      color: #333;
      margin-top: -20px;
    }
    img {
      width: 26px;
    }
  }
  > div {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`;

export const ButtonOutline = styled.button`
  width: 90px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  border: 1px solid #FF2B3A;
  border-radius: 100px;
  color: #333;
  svg {
    margin-bottom: 4px;
  }
`;

export const MenuContent = styled.div`
  padding: 8px;
  background: #fff;
  border-radius: 7px;
  margin-top: 70px ;

  ${props => props.scrolling && css`
    margin-top: -200px;
  `}
`;

export const MenuItem = styled.li`
  list-style: none;
  padding: 5px;

`;

export const Badge = styled.div`
  font-size: 10px;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );

  max-width: 100px;
  padding: 8px;
  min-height: 25px;
  color: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  text-align: center;
  font-weight: bold;
`;

export const MenuItemBody = styled.div`
width: 100% ;
  max-width: 414px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1px ;
`;

export const ProductsArea = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: center ;
  position: relative ;

`

export const AddToCart = styled.div`
 width: 113px;
  height: 177px;

  margin-bottom: 10px;
  background-color: #f8f9fa;
  border-radius: 7px;
  text-align: center;
  box-shadow: ${(props) =>
    props.isProductSelected ? '1px 1px 3px #fb4a20' : '1px 1px 3px #33333320'};
  transition: transform 0.7s;
  cursor: pointer;


`;
export const ProductInfo = styled.div`
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-family: 'poppins' ;
`;

export const ProductImage = styled.div`
  width: 113px;
  border-radius: 7px 7px 0 0;
  height: 110px;

  img {
    object-fit: cover;
    width: 113px;
    border-radius: 7px 7px 0 0;
  height: 110px;
  }
  /* background-image: url(${(props) => props.link});
  background-position: center; //centraliza imagem
  background-size: cover; //imagem cobre toda área do div */
`;

export const ProductPrice = styled.span`
  font-weight: 12px;
  font-size: 12px;
`;

export const ProductName = styled.span`
  font-weight: bold;
  font-size: 14px;

  line-height: 15px;
`;


export const ButtonGoToCart = styled.div`
  width: 58px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FF2129;
  border-radius: 50%;
  margin-bottom: 25px;
  position: relative;
  cursor: pointer;
`;

export const CountBadge = styled(NotificationBadge)`
  position: absolute;
  top: 0;
  left: 45px;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  background: #fff;
  width: 22px;
  height: 22px;
  color: rgba(251, 74, 32, 1);
  border: 1px solid rgba(251, 74, 32, 1);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuItemHeader = styled.div`
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  border-radius: 28px;
  padding: 5px;
  margin-top: 10px;

  span {
    margin-left: 8px;
  }
  strong {
    font-weight: 700;
    margin-left: 8px;
  }
`;


export const MenuItemDescription = styled.div`
  width: 190px;
  margin-left: 8px;
  color: #333;
  strong {
    font-weight: 900;
  }
  p {
    font-size: 14px;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${props =>
    props.disable === 'true' &&
    css`
      filter: opacity(30%);
    `}
`;

export const MenuItemPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #333;
  p {
    font-size: 14px;
    font-weight: 900;
    font-family: Arial, Helvetica, sans-serif;
  }
`;

export const ButtonAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
`;

export const MenuItemPromotion = styled.p`
  color: red;
  text-decoration: line-through;

  filter: opacity(60%);
`;

export const SoldOff = styled.div`
  color: red;
  font-size: 14px;
  font-weight: bold;

  margin-top: 8px;
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #4ceb5b;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const RestaurantClosed = styled.div`
  position: fixed;
  width: 100%;
  max-width: 410px;
  height: 30px;
  top: 113px;
  background-color: rgba(251, 74, 32, 1);
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
    color: #fff;
  }
`;

export const Finder = styled.form`
  background: #fff;
  width: 100%;
  max-width: 410px;
  height: 54px;
  position: fixed;
  top: 110px;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    border: 1px solid rgba(251, 74, 32, 1);
    border-radius: 7px;
    text-align: center;
    height: 32px;

    &::placeholder {
      font-size: 12px;
      text-align: center;
    }
  }

  button{
    width: 50px;
    height: 32px;
    margin-left: 4px;
    border: none;
    border-radius: 7px;
    font-size: 12px;
    color: #fff;
    background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  }
`
export const StartingPrice = styled.span`
  font-weight: bold;
  font-size: 12px;
  color: #adadad;
`;


export const SearchBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  margin-top: 8px;

  position: relative ;

svg {
  color:  #ff2b3a90;
  position: absolute;
  left: 20px;
}

  input {
    width: 95%;
    padding: 10px 15px;
    border-radius: 7px;
    border: 1px solid #D9D9D9;
    position: relative ;
    color: #666666;
    padding-left: 40px ;
    ::placeholder{
      color: #666666;
    }
    padding-left: 40px ;
  }
`;
