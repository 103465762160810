import React, { useState, useEffect, useRef } from 'react';

import OrderClosedItem from './OrderClosedItem';

import { useAuth } from '../../context/auth';
import Select from 'react-select'

import api from '../../services/api';

import Footer from '../../components/Footer';

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  HeaderAction,
  SelectArea
} from './styles';
import { useOrders } from '../../context/ordersContext';

const OrdersClosed = props => {
  const { restaurantFantasyName } = useAuth();
  const [orders, setOrders] = useState([])
  const [path, setPath] = useState('');

  const { ordersAccepted, getOrders } = useOrders()



  async function getMenuFilteredWaiters() {


    const orders = ordersAccepted
      .filter((order) => !order.basket?.bill.buyer && order.waiter_checked)
      .sort((a, b) => Number(b.id) - Number(a.id));

    setOrders(orders);


  }

  async function getMenuFilteredClients() {


    const orders = ordersAccepted
      .filter((order) => order.basket?.bill.buyer && order.waiter_checked)
      .sort((a, b) => Number(b.id) - Number(a.id));

    setOrders(orders);


  }

  function setOrderOwner(e) {
    switch (e) {
      case 'waiter':
        getMenuFilteredWaiters();
        break;
      case 'client':
        getMenuFilteredClients();
        break;
      default:
        getOrders();
        setOrders([])
        break;
    }
  }


  const selectOtions = [
    { value: 'waiter', label: 'Garçom' },
    { value: 'client', label: 'Cliente' },
    { value: 'both', label: 'Ambos' },
  ];

  useEffect(() => {

    setPath(window.location.pathname);
  }, []);

  const [cabecalhoHeight, setCabecalhoHeight] = useState(0);

  useEffect(() => {
    const cabecalhoElemento = document.getElementById("cabecalho");

    if (cabecalhoElemento) {
      const height = cabecalhoElemento.clientHeight;
      setCabecalhoHeight(height);
    }
  }, []);

  return (
    <Container>
      <Content>
        <HeaderAction id="cabecalho">
          <header>
            <h1>{restaurantFantasyName}</h1>
            <div>Itens entregues</div>
          </header>
          <SelectArea>
            <label>Ver pedidos de:</label>
            <Select placeholder="Garçom/ Cliente" options={selectOtions} onChange={(e) => { setOrderOwner(e.value) }} />
          </SelectArea>
        </HeaderAction>

        <MenuContent cabecalhoHeight={cabecalhoHeight}>
          <MenuItem>
            {orders < 1 && ordersAccepted && ordersAccepted.length > 0 && ordersAccepted
              .map(item => (
                <OrderClosedItem
                  item={item}
                  getOrders={getOrders}
                  key={item?.id} />
              ))}

            {orders && orders.length > 0 && orders
              .map(item => (
                <OrderClosedItem
                  item={item}
                  getOrders={getOrders}
                  key={item?.id} />
              ))}
          </MenuItem>
        </MenuContent>
        {console.log(ordersAccepted)}

        <Footer />
      </Content>
    </Container>
  );
};

export default OrdersClosed;
