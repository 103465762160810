import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;

  padding: 10px;

  display: flex;
  flex-direction: column;
  padding-bottom: 35px;

  padding-top: 0px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }

    a {
      color: #fb4a20;
      font-size: 12px;
      font-weight: 900;
    }
  }
`;

export const MenuContent = styled.div`
  border-radius: 7px;
  padding-bottom: 130px;
`;

export const MenuItem = styled.li`
  width: 100%;
  max-width: 410px;

  padding-top: 15px;

  list-style: none;

  div {
    font-family: Arial, Helvetica, sans-serif;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  max-width: 410px;

  padding-right: 30px;
  padding-left: 10px ;


  background: #fff;
  border-top: 1px solid #f8f8f8;
  position: fixed;
  bottom: 10px;




  input {
    border-radius: 24px;
    text-align: center;
    height: 48px;
    color: #ff2c19;

    border: 1px solid #fb4a20cc;

    ::placeholder {
      color: #ff2c1990;

    }
  }
`;

export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonOutline = styled.button`
  width: 90px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  border: none;
  border-radius: 100px;
  color: #333;
  background: transparent;
  svg {
    margin-bottom: 4px;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: space-between;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #2EC9B7;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #FF1F2B;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const PrintButton = styled.button`
  width: 40px;
  height: 40px;
  display: flex;

  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-left: 8px;

  border: 1px solid #FF1F2B;
  border-radius: 5px;
  color: #333;
  background: transparent;


  svg{
    margin: 0
  }

`;

export const ClosingButton = styled.button`
  width: 180px;
  height: 48px;
  background: #FF2B3A;
  color: #fff;
  border-radius: 24px;
  border: none;
  font-weight: bold;
  font-size: 16px;
`

export const ButtonActionToCart = styled.button`
  background: transparent;
  border: none;
`;

export const CartDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 38px;

  strong {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
  }
`;

export const ItemListHeader = styled.div`
  color: #333;
  font-size: 14px;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

export const MenuItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F8F8F7;
  border-radius: 7px;
  padding: 5px;
  margin-top: 8px;

  > div {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #4D4D4C;


  }

  strong {
    font-weight: 700;
  }
`;

export const Orders = styled.div`


  ul {
    width: 100%;
    max-width: 410px;
    li {
      list-style: none;
      display: flex;
      justify-content: space-between;

      > div {
        display: flex;

        > div {
          display: flex;
          margin-left: 8px;
        }

        p {
          margin-right: 8px;
          margin-top: 8px;
        }
      }

      p {
        font-size: 14px;
      }
    }
  }
`;

export const MenuItemBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 14px;

  .bill_finished {
    color: red;
    text-decoration: line-through;
  }

  ul {
    li {
      p {
        display: flex;
        align-items: flex-end;
      }
      > div {
        > p {
          margin-right: 16px;
        }
      }
    }
  }
`;

export const MenuItemProducts = styled.div``;

export const ServiceTax = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    p {
      font-size: 14px;
    }
  }
`;

export const DescriptionText = styled.p`
  font-size: 14px;
  line-height: 20px;
`;

export const PriceTextArea = styled.p`
display: flex;
align-items: center;
margin-bottom: 20px;

`;

export const PriceTotalText = styled.span`

  font-size: 18px;
  color: #666666;
  font-weight: 500;

`;

export const PriceText = styled.span`
margin-left: 8px;
  font-size: 27px;
  color: #ff2c19;
  font-weight: 500;

`;
